const OWN_CLOUDFLARE_URL = 'https://enftmemes.infura-ipfs.io/ipfs/';

class IpfsHelper {
  getUrl(uri): string {
    const match = uri.match(/https:\/\/(.*)\/ipfs\/(.*)/);
    const id = match[2] || null;

    if (id) {
      return OWN_CLOUDFLARE_URL + id;
    }

    if (uri.startsWith('https')) {
      return uri;
    }

    // Is IPFS hash v0
    if (uri.startsWith('Qm') && uri.length === 46) {
      return OWN_CLOUDFLARE_URL + uri;
    }

    return uri;
  }
}

const ipfsHelper = new IpfsHelper();

export default ipfsHelper;
