import memeStakingContract, { CALCULATED_REWARD, MEME_STAKING_CONSTANTS } from "@/contracts/memeStaking";
import elrondHelper from "@/helpers/elrond";
import BLOCKCHAIN from "@/constants/blockchain";
import elrondApiHelper, { TOP_NFT_POST } from "@/helpers/elrondApi";
import storeHelper, { LIST } from "@/helpers/store";
import { STAKED_POSITION } from "@/helpers/transformHelper";
import { BigNumber } from "bignumber.js";
import { TokenPayment } from "@multiversx/sdk-core";
import { REFERRAL_MUTATIONS } from "@/store/referrals";
import ELROND from "@/constants/elrond";
import stakingClient from "@/api/staking";

export const STAKING_GETTERS = {
  REWARDS: "stakingRewards",
  CURRENT_BLOCK: "stakingCurrentBlock",
  POSITIONS: "stakingPositions",
};

export const STAKING_MUTATIONS = {
  REWARDS: 'stakingRewards',
  CURRENT_BLOCK: 'stakingCurrentBlock',
  POSITIONS: 'stakingPositions',
};

export const STAKING_ACTIONS = {
  GET_REWARDS: 'stakingGetRewards',
  STAKE: 'stakingStake',
  UNSTAKE: 'stakingUnstake',
  CLAIM_REWARDS: 'stakingClaimRewards',
  GET_POSITIONS: 'stakingGetPositions',
};

export interface STAKED_POSITION_FULL extends STAKED_POSITION {
  nft: TOP_NFT_POST;
  calculatedRewards: CALCULATED_REWARD[],
}

export interface REWARD_TOKEN_FULL {
  token: string;
  startBlock: number;
  endBlock: number;
  totalRewards: TokenPayment;
  rewardPerDay: TokenPayment;
  amountPerNftRare1: BigNumber;
}

export interface REWARDS {
  rewardTokens: REWARD_TOKEN_FULL[],
  stakeModifierTotal: number,
  percentPerNftRare1: number,
}

interface STAKING_STORE_STATE {
  rewards?: REWARDS;
  currentBlock?: number;
  positions: LIST<STAKED_POSITION_FULL>;
}

export const stakingStore = {
  state: (): STAKING_STORE_STATE => ({
    rewards: null,
    currentBlock: null,
    positions: null,
  }),
  getters: {
    [STAKING_GETTERS.REWARDS](state) {
      return state.rewards;
    },
    [STAKING_GETTERS.CURRENT_BLOCK](state) {
      return state.currentBlock;
    },
    [STAKING_GETTERS.POSITIONS](state) {
      return state.positions;
    },
  },
  mutations: {
    [STAKING_MUTATIONS.REWARDS](state, rewards) {
      state.rewards = rewards;
    },
    [STAKING_MUTATIONS.CURRENT_BLOCK](state, currentBlock) {
      state.currentBlock = currentBlock;
    },
    [STAKING_MUTATIONS.POSITIONS](state, positions) {
      state.positions = positions;
    },
  },
  actions: {
    async [STAKING_ACTIONS.GET_REWARDS]({ commit }) {
      const currentBlock = await elrondHelper.getCurrentBlock(elrondHelper.getAddressShard(BLOCKCHAIN.MEMES_STAKING_CONTRACT));

      commit(STAKING_MUTATIONS.CURRENT_BLOCK, currentBlock);

      const actualStakeModifierTotal = await stakingClient.stakeModifierTotal();
      const stakeModifierTotal = Math.max(actualStakeModifierTotal, 100);

      const allRewardTokens = await stakingClient.allRewardTokensInfo();

      const rewardTokens: REWARD_TOKEN_FULL[] = allRewardTokens.map(reward => {
        let remainingReward = reward.rewardPerBlock
          .dividedBy(MEME_STAKING_CONSTANTS.DIVISION_SAFETY_CONSTANT, 10)
          .multipliedBy(reward.endBlock - currentBlock);

        const decimals = BLOCKCHAIN.STAKING_TOKENS[reward.token]?.decimals || 18;

        if (remainingReward.comparedTo(0, 10) < 0) {
          remainingReward = new BigNumber(0, 10);
        }

        return {
          token: reward.token,
          startBlock: reward.startBlock,
          endBlock: reward.endBlock,
          totalRewards: new TokenPayment(reward.token, 0, reward.totalRewards, decimals),
          rewardPerDay: new TokenPayment(
            reward.token,
            0,
            reward.rewardPerBlock
              .dividedBy(MEME_STAKING_CONSTANTS.DIVISION_SAFETY_CONSTANT, 10)
              .multipliedBy(ELROND.BLOCKS_PER_DAY, 10),
            decimals,
          ),
          amountPerNftRare1: remainingReward.multipliedBy(100, 10).dividedToIntegerBy(stakeModifierTotal, 10),
        };
      });

      commit(STAKING_MUTATIONS.REWARDS, {
        rewardTokens,
        stakeModifierTotal: actualStakeModifierTotal,
        percentPerNftRare1: Math.round((100 / (stakeModifierTotal / 100) + Number.EPSILON) * 100) / 100,
      });
    },
    async [STAKING_ACTIONS.STAKE]({ commit }, { accountElrond, nonce }) {
      commit(REFERRAL_MUTATIONS.MAX_REFERALS_INFO, null);

      return await memeStakingContract.stake(accountElrond, nonce);
    },
    async [STAKING_ACTIONS.UNSTAKE](_, { accountElrond, nonce }) {
      return await memeStakingContract.unstake(accountElrond, nonce);
    },
    async [STAKING_ACTIONS.CLAIM_REWARDS](_, { accountElrond, nonce }) {
      return await memeStakingContract.claimRewards(accountElrond, nonce);
    },
    async [STAKING_ACTIONS.GET_POSITIONS]({ commit }, { address, page }) {
      const total = await elrondApiHelper.getAddressNftsCount(address, BLOCKCHAIN.META_STAKING_TOKEN_IDENTIFIER);
      const response = await elrondApiHelper.getAddressStakedPositions(address, page, total);

      const calculatedRewards = await memeStakingContract.calculateRewards(response.map(v => v.baseStruct));

      // @ts-ignore
      const topNfts: TOP_NFT_POST = await elrondApiHelper.getNfts(response.map(position => position.nftNonce), BLOCKCHAIN.TOP_NFT_TOKEN_IDENTIFIER);

      const positions: STAKED_POSITION_FULL[] = response.map((position, index) => ({
        ...position,
        nft: topNfts?.[position.nftNonce],
        calculatedRewards: calculatedRewards[index],
      }));

      storeHelper.commitList(commit, STAKING_MUTATIONS.POSITIONS, positions, page, total);
    },
  },
};
