import GENERAL from "@/constants/general";

export interface LIST<T> {
  items: T[];
  page: number;
  total: number;
  perPage: number;

  [index: string]: any;
}

const storeHelper = {
  commitList(commit, mutation, response, page, total, extra = {}) {
    commit(mutation, {
      items: response,
      page,
      total: total,
      perPage: GENERAL.PER_PAGE,
      ...extra,
    });
  },
  editList(list: LIST<any>, newItem: any) {
    if (!list?.items) {
      return list;
    }

    const itemIndex = list.items.findIndex(item => item.nonce === newItem.nonce);
    if (itemIndex !== -1) {
      const newItems = [...list.items];
      newItems[itemIndex] = newItem;
      return {
        ...list,
        items: newItems,
      };
    }
  },
};

export default storeHelper;
