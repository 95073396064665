import {
  Account,
  Address,
  ITransactionOnNetwork,
  ResultsParser,
  Transaction,
  TransactionWatcher,
} from '@multiversx/sdk-core';
import ELROND from '@/constants/elrond';
import elrondApiHelper from '@/helpers/elrondApi';
import ENV from '@/constants/env';
import { NetworkConfig, ProxyNetworkProvider, TransactionOnNetwork } from '@multiversx/sdk-network-providers';
import { ExtensionProvider } from '@multiversx/sdk-extension-provider';
import { WalletConnectV2Provider } from '@multiversx/sdk-wallet-connect-provider';
import { HWProvider } from '@multiversx/sdk-hw-provider';
import { TransactionDecoder } from '@multiversx/sdk-transaction-decoder/lib/src/transaction.decoder';

class ElrondHelper {
  provider: ExtensionProvider | WalletConnectV2Provider | HWProvider;
  proxy: ProxyNetworkProvider;
  cachedProxy: ProxyNetworkProvider;
  networkConfig: NetworkConfig;
  transactionCache: {
    [hash: string]: TransactionOnNetwork;
  };

  constructor() {
    this.transactionCache = {};

    this.proxy = new ProxyNetworkProvider(ELROND.GATEWAY, { timeout: 10000 });
    this.cachedProxy = new ProxyNetworkProvider(ENV.MICROSERVICE_URL, { timeout: 10000 });
  }

  async setNetworkConfig() {
    this.networkConfig = await this.cachedProxy.getNetworkConfig();
  }

  async initializeMaiar(onClientLogin, onClientLogout) {
    await this.setNetworkConfig();

    this.provider = new WalletConnectV2Provider(
      {
        onClientLogin,
        onClientLogout,
        onClientEvent: () => {},
      },
      this.networkConfig.ChainID,
      ELROND.WALLET_CONNECT_WS,
      ELROND.WALLET_CONNECT_PROJECT_ID
    );

    return await this.provider.init();
  }

  async initializeExtension(initialAddress) {
    if (!initialAddress) {
      this.provider = ExtensionProvider.getInstance();
    } else {
      this.provider = ExtensionProvider.getInstance();

      this.provider.setAddress(initialAddress);
    }

    await this.setNetworkConfig();

    return await this.provider.init();
  }

  async initializeLedger() {
    this.provider = new HWProvider();

    await this.setNetworkConfig();

    return await this.provider.init();
  }

  async login(redirect = "", token = "", addressIndex = null) {
    if (this.provider instanceof HWProvider) {
      if (token) {
        return await this.provider.tokenLogin({
          token: Buffer.from(`${ token }{}`),
          addressIndex,
        });
      }

      await this.provider.setAddressIndex(addressIndex);

      return '';
    }

    if (this.provider instanceof WalletConnectV2Provider) {
      const { uri, approval } = await this.provider.connect()

      this.provider.login({
        approval,
        token,
      });

      return uri;
    }

    return await this.provider.login({
      callbackUrl: window.location.origin + redirect,
      token,
    });
  }

  async getAddress() {
    return await this.provider.getAddress();
  }

  async getAccount(address): Promise<Account> {
    return await elrondApiHelper.getAccount(address);
  }

  async logout(): Promise<void> {
    if (this.provider) {
      await this.provider.logout();
    }
  }

  async getCurrentBlock(shard: number, force: boolean = false): Promise<number> {
    let result;

    if (force) {
      result = await this.proxy.doGetGeneric(`network/status/${ shard }`);
    } else {
      result = await this.cachedProxy.doGetGeneric(`network/status/${ shard }`);
    }

    return result.status.erd_nonce;
  }

  getAddressShard(addressStr: string): number {
    const address = Address.fromString(addressStr);
    const numShards = 3; // TODO: Get from Gateway /network/config? Code might not work for more than 3 shards though
    const maskHigh = parseInt("11", 2);
    const maskLow = parseInt("01", 2);
    const pubKey = address.pubkey();
    const lastByteOfPubKey = pubKey[31];
    if (this.isAddressOfMetachain(pubKey)) {
      return 4294967295;
    }
    let shard = lastByteOfPubKey & maskHigh;

    if (shard > numShards - 1) {
      shard = lastByteOfPubKey & maskLow;
    }

    return shard;
  }

  isAddressOfMetachain(pubKey: Buffer): boolean {
    // prettier-ignore
    const metachainPrefix = Buffer.from([
      0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ]);
    const pubKeyPrefix = pubKey.slice(0, metachainPrefix.length);

    if (pubKeyPrefix.equals(metachainPrefix)) {
      return true;
    }

    const zeroAddress = Buffer.alloc(32).fill(0);

    return pubKey.equals(zeroAddress);
  }

  async sendTransaction(transaction: Transaction, account: Account): Promise<Transaction | null> {
    const { default: store, BASE_MUTATIONS } = await import("@/store");

    store.commit(BASE_MUTATIONS.SIGN_TRANSACTION_TOAST, true);

    try {
      // @ts-ignore
      await this.provider.signTransaction(transaction);
      await this.proxy.sendTransaction(transaction);

      account.incrementNonce();

      return transaction;
    } catch (e) {
      console.error(e);

      return null;
    } finally {
      store.commit(BASE_MUTATIONS.SIGN_TRANSACTION_TOAST, null);
    }
  }

  getTransactionStatus(transaction?: TransactionOnNetwork): boolean | string {
    if (!transaction) {
      return false;
    }

    const resultsParser = new ResultsParser();

    const result = resultsParser.parseUntypedOutcome(transaction);

    if (!transaction.contractResults) {
      return transaction.status.isSuccessful();
    }

    try {
      if (transaction.status.isSuccessful() && result.returnCode.isSuccess()) {
        return true;
      }

      if (result.returnMessage) {
        return result.returnMessage;
      }
    } catch (e) {
      console.error(e);
    }

    return false;
  }

  async getPendingTransaction(
    address: string | boolean,
    contract: string,
    fct: string,
    transaction: Transaction | null,
    returnTransaction: boolean = false,
  ): Promise<boolean | string | null | ITransactionOnNetwork> {
    if (!transaction) {
      return null;
    }

    const { default: store, BASE_MUTATIONS } = await import("@/store");

    const hash: string = transaction.getHash().toString();

    store.commit(BASE_MUTATIONS.TRANSACTION_TOAST, { hash, status: null });

    const transactionWatcher = new TransactionWatcher(elrondApiHelper.apiProvider);
    const transactionOnNetwork: any = await transactionWatcher.awaitCompleted(transaction);

    if (!hash) {
      return null;
    }

    let newTransaction: TransactionOnNetwork = this.transactionCache[hash];

    if (!newTransaction) {
      newTransaction = transactionOnNetwork;

      if (!newTransaction) {
        return null;
      }

      this.transactionCache[hash] = newTransaction;
    }

    // Check if transaction is for the desired contract and function
    if (contract && fct) {
      const decoder = new TransactionDecoder();

      const metadata = decoder.getTransactionMetadata({
        sender: newTransaction.sender.bech32(),
        receiver: newTransaction.receiver.bech32(),
        data: newTransaction.data.toString("base64"),
        value: newTransaction.value.toString(),
      });

      if (metadata.receiver !== contract || fct !== metadata.functionName) {
        return null;
      }
    }

    const status = this.getTransactionStatus(newTransaction);

    store.commit(BASE_MUTATIONS.TRANSACTION_TOAST, { hash, status });

    if (true === status && returnTransaction) {
      return transactionOnNetwork;
    }

    return status;
  }
}

const elrondHelper = new ElrondHelper();

export default elrondHelper;
