import BLOCKCHAIN from '@/constants/blockchain';
import BaseContract from '@/contracts/baseContract';
import {
  Account,
  Address,
  ContractFunction,
  Interaction,
  Struct,
  TokenPayment,
  Transaction,
  U8Value,
} from '@multiversx/sdk-core';
import elrondHelper from '@/helpers/elrond';
import { BigNumber } from 'bignumber.js';

export interface CALCULATED_REWARD {
  token: string;
  reward: TokenPayment;
}

export interface MAX_REFERALS_INFO {
  nb: number;
  max: number;
}

export const MEME_STAKING_CONSTANTS = {
  MINUMUM_LOCK_BLOCKS: 5 * 14_400, // 5 days
  DIVISION_SAFETY_CONSTANT: 1000000000,

  TOP_RARITY: 10,

  BASE_STAKE_MODIFIER: 1, // 1x
  INCREMENT_STAKE_MODIFIER: 0.1, // 0.1x
  TOP_RARITY_STAKE_MODIFIER: 2, // 2x
  SUPER_RARE_STAKE_MODIFIER: 2.25, // 2.25x
}

export const MEME_STAKING_FUNCTIONS = {
  STAKE: 'stake',
  UNSTAKE: 'unstake',
  CLAIM_REWARDS: 'claim_rewards',
  USE_REFERER: 'use_referer',
};

class MemeStakingContract extends BaseContract {
  constructor() {
    super(BLOCKCHAIN.MEMES_STAKING_CONTRACT, 'memes-staking', 'MemesStaking');
  }

  // Endpoints
  async stake(account: Account, nonce: number): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[MEME_STAKING_FUNCTIONS.STAKE]([])
      .withNonce(account.nonce)
      .withGasLimit(12000000) // 7,000,000 from Mandos + 5,000,000 just in case
      .withChainID(elrondHelper.networkConfig.ChainID);

    interaction.withSingleESDTNFTTransfer(TokenPayment.nonFungible(BLOCKCHAIN.TOP_NFT_TOKEN_IDENTIFIER, nonce), account.address);

    const transaction = interaction.buildTransaction();

    return await elrondHelper.sendTransaction(transaction, account);
  }

  async unstake(account: Account, nonce: number): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[MEME_STAKING_FUNCTIONS.UNSTAKE]([])
      .withNonce(account.nonce)
      .withGasLimit(13000000) // 8,000,000 from Mandos + 5,000,000 just in case
      .withChainID(elrondHelper.networkConfig.ChainID);

    interaction.withSingleESDTNFTTransfer(TokenPayment.metaEsdtFromAmount(BLOCKCHAIN.META_STAKING_TOKEN_IDENTIFIER, nonce, 1, 0), account.address);

    const transaction = interaction.buildTransaction();

    return await elrondHelper.sendTransaction(transaction, account);
  }

  async claimRewards(account: Account, nonce: number): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[MEME_STAKING_FUNCTIONS.CLAIM_REWARDS]([])
      .withNonce(account.nonce)
      .withGasLimit(13000000) // 8,000,000 from Mandos + 5,000,000 just in case
      .withChainID(elrondHelper.networkConfig.ChainID);

    interaction.withSingleESDTNFTTransfer(TokenPayment.metaEsdtFromAmount(BLOCKCHAIN.META_STAKING_TOKEN_IDENTIFIER, nonce, 1, 0), account.address);

    const transaction = interaction.buildTransaction();

    return await elrondHelper.sendTransaction(transaction, account);
  }

  async useReferer(account: Account, referer: string): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[MEME_STAKING_FUNCTIONS.USE_REFERER]([referer])
      .withNonce(account.nonce)
      .withGasLimit(18000000) // 13,000,000 from Mandos + 5,000,000 just in case
      .withChainID(elrondHelper.networkConfig.ChainID);

    const transaction = interaction.buildTransaction();

    return await elrondHelper.sendTransaction(transaction, account);
  }

  // Views
  async calculateRewards(positions: Struct[]): Promise<CALCULATED_REWARD[][]> {
    if (!positions.length) {
      return [];
    }

    const query = await this.contract.createQuery({
      func: new ContractFunction("calculate_rewards_for_multiple_positions"),
      args: positions,
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = (await this.getContractAbi()).getEndpoint("calculate_rewards_for_multiple_positions");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    const values: {field0: string, field1: BigNumber}[] = firstValue.valueOf()[0];

    let tokensLength = values.length / positions.length;
    let tempResult: CALCULATED_REWARD[] = [];

    const fullResult: CALCULATED_REWARD[][] = [];

    values.forEach(v => {
      tokensLength--;

      const token = v.field0;

      tempResult.push({
        token,
        reward: new TokenPayment(token, 0, v.field1, BLOCKCHAIN.STAKING_TOKENS[token].decimals),
      });

      if (0 === tokensLength) {
        fullResult.push(tempResult);
        tempResult = [];
        tokensLength = values.length / positions.length;
      }
    });

    return fullResult;
  }

  async getMaxReferalsInfo(address: string): Promise<MAX_REFERALS_INFO> {
    await this.getContractAbi();

    const interaction = <Interaction>this.contract.methods.get_max_referals_info([address]);
    const query = interaction.check().buildQuery();
    const response = await elrondHelper.cachedProxy.queryContract(query);

    const { firstValue, secondValue } = this.resultParser.parseQueryResponse(response, interaction.getEndpoint());

    return {
      nb: (firstValue as U8Value).value.toNumber(),
      max: (secondValue as U8Value).value.toNumber(),
    };
  }

  async getReferer(account: Account): Promise<string | null> {
    await this.getContractAbi();

    const interaction = <Interaction>this.contract.methods.referer([account.address]);
    const query = interaction.check().buildQuery();
    const response = await elrondHelper.cachedProxy.queryContract(query);

    if (!response.returnData.length) {
      return null;
    }

    const { firstValue } = this.resultParser.parseQueryResponse(response, interaction.getEndpoint());

    return (firstValue.valueOf() as Address).bech32();
  }

  // Helpers
  calculateStakeModifier(rarity: number): number {
      // Rare (10) NFT
    if (MEME_STAKING_CONSTANTS.TOP_RARITY === rarity) {
      return MEME_STAKING_CONSTANTS.TOP_RARITY_STAKE_MODIFIER;
    }

    // Super Rare NFT
    if (MEME_STAKING_CONSTANTS.TOP_RARITY < rarity) {
      return MEME_STAKING_CONSTANTS.SUPER_RARE_STAKE_MODIFIER;
    }

    // Rare (1-9) NFT
    return MEME_STAKING_CONSTANTS.BASE_STAKE_MODIFIER + MEME_STAKING_CONSTANTS.INCREMENT_STAKE_MODIFIER * (rarity - 1);
  }
}

const memeStakingContract = new MemeStakingContract();

export default memeStakingContract;
