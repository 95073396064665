
import { Modal } from "bootstrap";
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import { USER_ACTIONS, USER_GETTERS } from "@/store/user";
import formatterHelper from "@/helpers/formatter";
import { useI18n } from "vue-i18n";
import { TokenPayment, Transaction } from "@multiversx/sdk-core";
import { BigNumber } from "bignumber.js";
import { BASE_MUTATIONS } from "@/store";
import Loader from "@/components/helpers/Loader.vue";
import ELROND from "@/constants/elrond";
import elrondHelper from "@/helpers/elrond";
import BLOCKCHAIN, { ITOKEN } from '@/constants/blockchain';
import TokenSelect from '@/components/TokenSelect.vue';
import { ESDT_TRANSFER_FUNCTION_NAME } from '@multiversx/sdk-core/out/constants';

export default {
  name: "CreatorTipModal",
  components: { TokenSelect, Loader },
  props: {
    creator: String,
    onHide: Function,
  },
  created() {
    this.formatterHelper = formatterHelper;
    this.ELROND = ELROND;
    this.TIP_TOKENS = BLOCKCHAIN.TIP_TOKENS;
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();

    const modal = ref(null);
    const memeTipModalRef = ref(null);

    watch(memeTipModalRef, () => {
      modal.value = new Modal(memeTipModalRef.value);
      modal.value.show();
      memeTipModalRef.value.addEventListener("hidden.bs.modal", props.onHide);
    });

    const accountElrond = computed(() => store.getters[USER_GETTERS.ACCOUNT_ELROND]);
    const address = computed(() => store.getters[USER_GETTERS.ADDRESS_ELROND]);
    const tipAmount = ref(0);
    const tipError = ref(null);
    const loadingTip = ref(false);
    const tipTransactionStatus = ref(null);

    const checkTransactionStatus = async (transaction?: Transaction) => {
      const pendingTransactionStatus = await elrondHelper.getPendingTransaction(
        address.value,
        props.creator,
        ESDT_TRANSFER_FUNCTION_NAME,
        transaction,
      );

      if (null !== pendingTransactionStatus) {
        tipTransactionStatus.value = pendingTransactionStatus;
      }
    };

    const selectedToken = ref(Object.keys(BLOCKCHAIN.TIP_TOKENS)[0]);
    const selectedTokenInfo = computed<ITOKEN>(() => BLOCKCHAIN.TIP_TOKENS[selectedToken.value]);
    const accountTipTokenBalance = computed<TokenPayment>(() => store.getters[USER_GETTERS.TOKENS]?.[selectedToken.value]);

    const getTokenBalance = (token, force = false) => store.dispatch(USER_ACTIONS.GET_TOKEN_BALANCE, { token, decimals: BLOCKCHAIN.TIP_TOKENS[token].decimals, force });

    onMounted(async () => {
      loadingTip.value = true;

      await getTokenBalance(selectedToken.value);

      loadingTip.value = false;
    });

    watch(tipAmount, (newValue) => {
      if (!accountTipTokenBalance.value || new BigNumber(newValue).shiftedBy(selectedTokenInfo.value.decimals).comparedTo(accountTipTokenBalance.value.valueOf()) > 0) {
        tipError.value = t("meme.view.tip.error");

        return;
      }

      tipError.value = null;
    });

    const setMax = () => {
      if (!accountTipTokenBalance.value) {
        return;
      }

      tipAmount.value = Number.parseFloat(
        TokenPayment.fungibleFromBigInteger(
          selectedToken.value,
          accountTipTokenBalance.value.valueOf(),
          selectedTokenInfo.value.decimals
        ).toRationalNumber(),
      );
    };

    const onTip = async () => {
      loadingTip.value = true;

      const transaction = await store.dispatch(USER_ACTIONS.TIP, {
        accountElrond: accountElrond.value,
        payment: TokenPayment.fungibleFromAmount(
          selectedToken.value,
          tipAmount.value,
          selectedTokenInfo.value.decimals
        ),
        receiver: props.creator,
      });
      await checkTransactionStatus(transaction);

      loadingTip.value = false;
    };

    watch(tipTransactionStatus, async (newValue) => {
      // @ts-ignore
      if (true === newValue) {
        // Tip was done
        modal.value.hide();

        store.commit(BASE_MUTATIONS.MESSAGE_MODAL, {
          variant: "success",
          message: "meme.view.tip.success",
          messageParams: {
            address: formatterHelper.formatAddress(props.creator),
          },
        });

        await getTokenBalance(selectedToken.value, true);

        return;
      }

      store.commit(BASE_MUTATIONS.MESSAGE_MODAL, {
        variant: "danger",
        message: "messages.error.transaction",
      });
    });

    const getImgUrl = (tokenId) => {
      if (ELROND.EGLD_TOKEN === tokenId) {
        return require(`../../../assets/images/tokens/${tokenId}.png`);
      }

      return ELROND.TOKEN_LOGO.replace('{TOKEN}', BLOCKCHAIN.TIP_TOKENS[tokenId].logo);
    }

    watch(selectedToken, () => {
      tipAmount.value = 0;
    });

    return {
      modal,
      memeTipModalRef,
      onTip,
      tipAmount,
      accountElrond,
      tipError,
      setMax,
      loadingTip,
      accountTipTokenBalance,
      getImgUrl,
      selectedToken,
    };
  },
};
