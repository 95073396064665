
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { MEME_ACTIONS, MEME_GETTERS, MEME_MUTATIONS } from '@/store/meme';
import MemePost from '@/components/MemePost.vue';
import formatterHelper from '@/helpers/formatter';
import Pagination from '@/components/Pagination.vue';
import ListNone from '@/components/helpers/ListNone.vue';
import PageLoader from '@/components/helpers/PageLoader.vue';
import HelpAccordion from '@/components/helpers/HelpAccordion.vue';
import Loader from '@/components/helpers/Loader.vue';

export default {
  name: 'home',
  components: { Loader, HelpAccordion, PageLoader, ListNone, Pagination, MemePost },
  created() {
    this.formatterHelper = formatterHelper;
  },
  setup() {
    const store = useStore();
    const memes = computed(() => store.getters[MEME_GETTERS.LIST]);
    const currentPeriod = computed(() => store.getters[MEME_GETTERS.CURRENT_PERIOD]);

    const page = ref(-1);
    const loading = ref(false);

    const loadMore = async () => {
      page.value++;

      loading.value = true;

      await store.dispatch(MEME_ACTIONS.GET_LIST, { page: page.value });

      if (page.value === 0 && memes.value.items.length === memes.value.total) {
        await loadOlderMemes();
      }

      loading.value = false;
    };

    onMounted(async () => {
      store.commit(MEME_MUTATIONS.LIST, null);

      await loadMore();
    });

    const allPeriods = computed(() => store.getters[MEME_GETTERS.ALL_PERIODS]);
    const selectedPeriod = ref(currentPeriod.value);
    const otherPage = ref(0);

    const pageWithOlderMemes = ref(-1);
    const isLastPageWithOlderMemes = ref(false);

    const loadOlderMemes = async () => {
      loading.value = true;

      await store.dispatch(MEME_ACTIONS.GET_ALL_PERIODS);

      pageWithOlderMemes.value++;

      try {
        while (memes.value.items.length < (pageWithOlderMemes.value + 1) * memes.value.perPage) {
          // End of a period
          if (memes.value.items.length === memes.value.total) {
            const index = allPeriods.value.indexOf(selectedPeriod.value);

            if (index === 0) {
              isLastPageWithOlderMemes.value = true;

              break;
            }

            selectedPeriod.value = allPeriods.value[index - 1];
            otherPage.value = 0;
          } else {
            otherPage.value++;
          }

          await store.dispatch(MEME_ACTIONS.GET_LIST_OLDER, {
            page: otherPage.value,
            period: selectedPeriod.value,
            append: true,
          });
        }
      } catch (error) {
        console.error(error)
      } finally {
        loading.value = false;
      }
    };

    return {
      currentPeriod,
      memes,
      loading,
      loadMore,
      otherPage,
      loadOlderMemes,
      pageWithOlderMemes,
      isLastPageWithOlderMemes,
    };
  },
};
