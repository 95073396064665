import memeAuctionContract, { AUCTION, MEME_AUCTION_CONSTANTS } from '@/contracts/memeAuction';
import storeHelper, { LIST } from '@/helpers/store';
import { MEME_ACTIONS, MEME_GETTERS, MEME_MUTATIONS, NFT_POST_TOP } from '@/store/meme';
import generalHelper from '@/helpers/general';
import GENERAL from '@/constants/general';
import elrondApiHelper, { TOP_NFT_POST } from '@/helpers/elrondApi';
import { MEME_VOTING_CONSTANTS } from '@/contracts/memeVoting';
import memeClient from '@/api/meme';
import BLOCKCHAIN from "@/constants/blockchain";
import generalClient from '@/api/general';
import auctionClient from '@/api/auction';

export const AUCTION_GETTERS = {
  CURRENT: 'auctionCurrent',
  LIST: 'auctionList',
  CUSTOM_PERIODS: "auctionCustomPeriods",
  UNENDED_CUSTOM_PERIOD: "auctionUnendedCustomPeriod",
};

export const AUCTION_MUTATIONS = {
  CURRENT: 'auctionCurrent',
  LIST: 'auctionList',
  LIST_MODIFY: 'auctionListModify',
  CUSTOM_PERIODS: "auctionCustomPeriods",
};

export const AUCTION_ACTIONS = {
  GET: 'auctionGet',
  GET_LIST: 'auctionGetList',
  LOCK: 'auctionLock',
  BID: 'auctionBid',
  END: 'auctionEnd',
  UPGRADE: 'auctionUpgrade',
  GET_CUSTOM_PERIODS: "auctionGetCustomPeriods",
};

export interface AUCTION_FULL extends AUCTION {
  nft: NFT_POST_TOP;
}

interface AUCTION_LIST extends LIST<AUCTION_FULL> {
  bidTimeTill: number;
  auctionTimeTill: number;
  status: string;
}

interface AUCTION_STORE_STATE {
  current?: AUCTION_FULL;
  list?: AUCTION_LIST;
  customPeriods?: number[];
}

export const auctionStore = {
  state: (): AUCTION_STORE_STATE => ({
    current: null,
    list: null,
    customPeriods: null,
  }),
  getters: {
    [AUCTION_GETTERS.CURRENT](state) {
      return state.current;
    },
    [AUCTION_GETTERS.LIST](state) {
      return state.list;
    },
    [AUCTION_GETTERS.CUSTOM_PERIODS](state) {
      return state.customPeriods;
    },
    [AUCTION_GETTERS.UNENDED_CUSTOM_PERIOD](state) {
      return state.customPeriods
        && GENERAL.AUCTION_STATUSES.ENDED !== generalHelper.getAuctionStatus(state.customPeriods[state.customPeriods.length - 1])
        ? state.customPeriods[state.customPeriods.length - 1]
        : null;
    },
  },
  mutations: {
    [AUCTION_MUTATIONS.CURRENT](state, auction) {
      state.current = auction;
    },
    [AUCTION_MUTATIONS.LIST](state, auctions) {
      state.list = auctions;
    },
    [AUCTION_MUTATIONS.LIST_MODIFY](state, newAuction) {
      state.list = storeHelper.editList(state.list, newAuction);
    },
    [AUCTION_MUTATIONS.CUSTOM_PERIODS](state, customPeriods) {
      state.customPeriods = customPeriods;
    },
  },
  actions: {
    async [AUCTION_ACTIONS.GET]({ commit, getters, dispatch }, { period, nonce, prevPeriod }) {
      const auction = await memeAuctionContract.periodMemeAuction(period, nonce);

      let nft = await dispatch(MEME_ACTIONS.GET_TOP_MEME, nonce);
      if (!nft) {
        nft = await elrondApiHelper.getNft(nonce);
      } else {
        auction.topNonce = (nft as TOP_NFT_POST).topNonce;
      }

      const votes = await dispatch(MEME_ACTIONS.GET_VOTES_PERIOD, {
        nonce: auction.nonce,
        period: prevPeriod,
      });
      const actualRarity = await dispatch(MEME_ACTIONS.GET_RARITY, nonce);

      return {
        ...auction,
        nft: {
          ...nft,
          votes,
          actualRarity,
        },
      };
    },
    // TODO: Fix bug with incorrect prevPeriod
    async [AUCTION_ACTIONS.GET_LIST]({ commit, getters, dispatch }, { period, prevPeriod }) {
      const response = (await memeAuctionContract.periodAuctionsMemesAll(period))
        .sort((a, b) => b.minBid - a.minBid);

      const allNonces = [];
      const normalMemeNonces = [];
      const topMemeNonces = [];

      response.forEach(({ nonce, topNonce }) => {
        allNonces.push(nonce);

        if (topNonce) {
          topMemeNonces.push(topNonce);
        } else {
          normalMemeNonces.push(nonce);
        }
      })

      const nftsVotes = await memeClient.memesVotes(allNonces, prevPeriod);

      const nfts = await elrondApiHelper.getNfts(normalMemeNonces);
      const topNfts = await elrondApiHelper.getNfts(Object.values(topMemeNonces), BLOCKCHAIN.TOP_NFT_TOKEN_IDENTIFIER);

      let startingRarity = response.length + 1;

      const auctions: AUCTION_FULL[] = await Promise.all(
        response.map(async (auction) => {
            const topNft = auction.topNonce && auction.topNonce in topNfts
              ? topNfts[auction.topNonce] : null;
            let nft = topNft || (auction.nonce in nfts ? nfts[auction.nonce] : null);

            // Edge case if NFT was upgraded before auction began
            if (!nft) {
              nft = await dispatch(MEME_ACTIONS.GET_TOP_MEME, auction.nonce);
              auction.topNonce = (nft as TOP_NFT_POST).topNonce;
            }

            const votes = nftsVotes[auction.nonce];

            startingRarity--;

            if (!nft) {
              return null;
            }

            return {
              ...auction,
              nft: {
                ...nft,
                votes,
                actualRarity: startingRarity,
              },
            };
          }
        ).filter(auction => auction !== null)
      );

      const bidTimeTill = period + MEME_AUCTION_CONSTANTS.BID_TIME;
      const auctionTimeTill = period + MEME_AUCTION_CONSTANTS.AUCTION_TIME;
      const status = generalHelper.getAuctionStatus(period);

      storeHelper.commitList(commit, AUCTION_MUTATIONS.LIST, auctions, 0, auctions.length, {
        bidTimeTill,
        auctionTimeTill,
        status,
        canBid: GENERAL.AUCTION_STATUSES.BIDDING === status,
        canLock: GENERAL.AUCTION_STATUSES.BIDDING === status || GENERAL.AUCTION_STATUSES.LOCK === status,
        upcoming: GENERAL.AUCTION_STATUSES.UPCOMING === status,
      });
    },
    async [AUCTION_ACTIONS.LOCK](_, { accountElrond, period, nonce, isTopMeme }) {
      return await memeAuctionContract.lockToken(accountElrond, period, nonce, isTopMeme);
    },
    async [AUCTION_ACTIONS.BID](_, { accountElrond, amount, period, nonce }) {
      return await memeAuctionContract.bid(accountElrond, amount, period, nonce);
    },
    async [AUCTION_ACTIONS.END](_, { accountElrond, period, nonce }) {
      return await memeAuctionContract.end(accountElrond, period, nonce);
    },
    async [AUCTION_ACTIONS.UPGRADE](_, { accountElrond, nonce, isTopMeme }) {
      return await memeAuctionContract.upgradeToken(accountElrond, nonce, isTopMeme);
    },
    async [AUCTION_ACTIONS.GET_CUSTOM_PERIODS]({ commit, getters }) {
      if (null !== getters[AUCTION_GETTERS.CUSTOM_PERIODS]) {
        return;
      }

      commit(AUCTION_MUTATIONS.CUSTOM_PERIODS, await auctionClient.customPeriods());
    },
  },
};
