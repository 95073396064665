const GENERAL = {
  DEFAULT_LANGUAGE: 'en',
  PER_PAGE: 10, // the same as in the contract

  AUCTION_STATUSES: {
    UPCOMING: 'upcoming',
    BIDDING: 'bidding',
    LOCK: 'lock',
    ENDED: 'ended',
  },

  MEME: {
    ACCEPTED_FILE_TYPES: [
      'image/jpeg',
      'image/png',
      'image/webp',
      'image/gif',
      'image/jfif',
    ],
    MAX_FILE_SIZE: 15 * 1024 * 1024,
  },

  STATUS_CODE_ACCESS_DENIED: 403,
  STATUS_CODE_INTERNAL_SERVER_ERROR: 500,

  SITE_URL: window.location.origin,
  REFERER_PARAM: 'referer',
};

export default GENERAL;
