import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import ENV from '../constants/env';
import storageHelper from '@/helpers/storage';
import GENERAL from '@/constants/general';

export const API_PATHS = {
  AUTH_TOKEN: '/auth/token',
  AUTH_LOGIN: '/auth/login',

  MEME_CREATE: '/meme/create',
  MEME_VOTE: '/meme/vote',
  MEME_VOTES_ALL: '/meme/{nonce}/votes-all',
  MEMES_VOTES_ALL: '/memes/votes-all',
  MEME_VOTES_PERIOD: '/meme/{nonce}/votes/{period}',
  MEMES_VOTES_PERIOD: '/memes/votes/{period}',
  MEMES_RESTRICTED: '/memes/restricted',

  MEME_RARITY: '/meme/{nonce}/rarity',
  MEMES_RARITY: '/memes/rarity',

  MEME_TOP_MEME: '/meme/{nonce}/top-meme',
  MEMES_TOP_MEME: '/memes/top-meme',

  CURRENT_PERIOD: '/current-period',
  ADDRESS_LAST_MEME_TIME: '/address-last-meme-time/{address}',
  PERIODS: '/periods',
  ADDRESS_VOTES: '/address-votes/{address}',
  ADDRESS_EXTRA_VOTES: '/address-extra-votes/{address}',

  AUCTION_CUSTOM_PERIODS: '/auction/custom-periods',

  ALL_REWARD_TOKENS_INFO: '/staking/all-reward-tokens-info',
  STAKE_MODIFIER_TOTAL: '/staking/stake-modifier-total',
};

class Api {
  public client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: ENV.MICROSERVICE_URL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    this.client.interceptors.request.use(
      (config) => {
        if (config.baseURL === ENV.MICROSERVICE_URL && !config.headers.Authorization) {
          const token = storageHelper.getBackendAccessToken();

          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        }

        return config;
      },
      (error) => Promise.reject(error)
    );
    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const { default: store, BASE_MUTATIONS } = await import('@/store');

        if (
          GENERAL.STATUS_CODE_ACCESS_DENIED === error.response.status &&
          error.response.config.headers.Authorization
        ) {
          storageHelper.clearLogins(true);
          window.location.reload();
        } else if (GENERAL.STATUS_CODE_INTERNAL_SERVER_ERROR === error.response.status) {
          store.commit(BASE_MUTATIONS.MESSAGE_MODAL, {
            variant: 'danger',
            message: 'messages.error.general',
          });
        }

        return Promise.reject(error);
      }
    );
  }

  async get(url: string, params: { [key: string]: any } = {}): Promise<AxiosResponse> {
    return await this.client.get(Api.withPathParams(url, params));
  }

  async post(url: string, data: { [key: string]: any } = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    return await this.client.post(url, data, config);
  }

  private static withPathParams(url: string, params: { [key: string]: any }) {
    for (const [key, value] of Object.entries(params)) {
      if (!value) {
        continue;
      }

      url = url.replace(`{${key}}`, value);
    }

    return url;
  }
}

const api = new Api();

export default api;
