import { BigNumber } from "bignumber.js";
import api, { API_PATHS } from "@/api/index";

export interface REWARD_TOKEN {
  token: string;
  startBlock: number;
  endBlock: number;
  totalRewards: BigNumber;
  rewardPerBlock: BigNumber;
}

class StakingClient {
  async allRewardTokensInfo(): Promise<REWARD_TOKEN[]> {
    const response = await api.get(API_PATHS.ALL_REWARD_TOKENS_INFO);

    return response.data.map(result => ({
      token: result.token,
      startBlock: result.startBlock,
      endBlock: result.endBlock,
      totalRewards: new BigNumber(result.totalRewards, 10),
      rewardPerBlock: new BigNumber(result.rewardPerBlock, 10),
    }));
  }

  async stakeModifierTotal(): Promise<number> {
    const response = await api.get(API_PATHS.STAKE_MODIFIER_TOTAL);

    return response.data;
  }
}

const stakingClient = new StakingClient();

export default stakingClient;