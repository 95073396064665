import api, { API_PATHS } from '@/api/index';

class GeneralClient {
  async currentPeriod(): Promise<number> {
    const response = await api.get(API_PATHS.CURRENT_PERIOD);

    return Number.parseInt(response.data);
  }

  async addressLastMemeTime(address: string): Promise<number> {
    const response = await api.get(API_PATHS.ADDRESS_LAST_MEME_TIME, { address });

    return response.data;
  }

  async periods(): Promise<number[]> {
    const response = await api.get(API_PATHS.PERIODS);

    return response.data.map(period => Number.parseInt(period));
  }

  async addressVotes(address: string): Promise<{ period: number; votes: number }> {
    const response = await api.get(API_PATHS.ADDRESS_VOTES, { address });

    return response.data;
  }

  async addressExtraVotes(address: string): Promise<number> {
    const response = await api.get(API_PATHS.ADDRESS_EXTRA_VOTES, { address });

    return response.data;
  }
}

const generalClient = new GeneralClient();

export default generalClient;
