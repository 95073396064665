import { pagesEN } from "@/translations/en/pages";

export const enMessages = {
  pages: pagesEN,
  menu: {
    home: 'Home',
    memes: {
      top: 'Top Memes',
      older: {
        title: 'Previous Periods',
        older: 'Memes',
        topOlder: 'Top Memes',
      }
    },
    auction: {
      current: 'Auction',
      special: 'Special Auction',
      older: 'Auctions',
    },
    staking: 'Staking',
    createMeme: 'Create',
    referrals: 'Referrals',
    faucet: 'Faucet',
    docs: 'Docs',
    login: 'Connect',
    logout: 'Logout',
    accountMemes: {
      title: 'Account NFTs',
      created: 'Created',
      wallet: 'Memes',
      topWallet: 'Top Memes',
    },
    account: {
      balance: 'Balance',
      votes: 'Remaining Votes',
      explorer: 'View on Explorer',
    },
  },
  login: {
    title: 'Connect to a {multiversX} wallet',
    extension: 'MultiversX DeFi Wallet',
    maiar: {
      title: 'xPortal App',
      info: 'Scan the QR code using xPortal',
      mobileInfo: 'or click the button below to open the App',
      mobileLink: 'xPortal Login',
    },
    ledger: {
      title: 'Ledger',
      connect: 'Connect Ledger',
      info: 'Unlock your device & open the Elrond App',
      error: 'Check if Elrond app is open on Ledger',
      addresses: {
        address: 'Address',
        balance: 'Balance',
        confirm: 'Confirm',
      },
      confirm: {
        title: 'Confirm Ledger Address',
        security: 'For security, please confirm that your address and Auth Token are the same as the ones shown on your Ledger device screen now.',
        approve: 'Select Authorize on your device to confirm, or reject if they don\'t match',
      },
    },
  },
  meme: {
    create: {
      title: 'Create Meme',
      form: {
        name: 'Name',
        category: 'Category',
        terms: 'By creating this NFT I acknowledge that the image/gif contains only original content and that it will become publicly viewable after upload!',
        submit: 'Create',
        error: {
          imageType: 'Image should be of type: jpeg, png, jfif, webp or a gif',
          imageSize: 'Image should have a maximum size of 15 MB'
        }
      },
      success: 'Meme successfully created!',
      error: {
        ipfs: 'Error while uploading image file...Please try again later.',
        throttle: 'Address already created a meme in the last 10 minutes. Please try again later.',
      },
    },
    view: {
      votes: 'votes',
      allVotes: 'total votes',
      creator: 'Creator',
      owner: 'Current Owner',
      period: 'Period',
      category: 'Category',
      rarity: {
        title: 'Rarity',
        actualTitle: 'Actual Rarity',
        currentTitle: 'Current Rarity',
        futureTitle: 'Future Rarity',
        common: 'Common',
        rare: 'Rare',
        superRare: 'Super Rare',
      },
      tip: {
        button: 'Tip',
        title: 'Send a tip to {address}',
        max: 'Max',
        confirm: 'Confirm Tip',
        error: 'The amount can\'t be greater than your current balance',
        success: 'You successfully tipped {address}!',
      },
      restricted: "This meme is restricted. <br> Are you sure you want to view it?"
    },
    categories: {
      funny: 'Funny',
    },
    vote: {
      pending: 'You have no pending votes | You have one pending vote | You have {count} pending votes',
      submit: 'Submit',
      success: 'Successfully voted!',
      warning: 'No votes currently left for this period...',
      error: 'Address already voted 20 memes in this period. Come back next period.',
      errorCaptcha: 'There was an error verifying the captcha... Please try again later',
    },
    list: {
      period: 'Current Period',
      total: 'Total memes',
      loadMore: 'Load More',
      top: {
        header: 'Current Top 10 Memes',
      },
      topOlder: {
        header: 'Older Top 10 Memes',
      },
      older: {
        header: 'Older Memes',
        periodLabel: 'Period',
      },
      none: 'There are currently no memes to display...',
      nonePeriods: 'There are currently no older periods to display...',
    },
    upgrade: {
      button: 'Upgrade',
      confirm: 'Upgrading will send the NFT to the auction contract to update it\'s attributes and then a Top Meme NFT will be sent back to you.',
      confirmTop: 'Upgrading will send the Top NFT to the auction contract to update it\'s attributes and then it will be sent back to you.',
      success: 'The NFT was successfully upgraded!',
      error: 'An error has occurred while submitting the transaction...The NFT might be already upgraded',
    }
  },
  user: {
    meme: {
      created: 'Memes Created',
      wallet: 'Memes In Wallet',
      topWallet: 'Top Memes In Wallet',
      total: 'Total memes',
      by: 'by',
      of: 'of',
    }
  },
  auction: {
    list: {
      current: {
        header: 'Current Auction',
        bidding: 'Bidding ends on {date}: {remaining} hours remaining',
        auction: 'Locking ends on {date}: {remaining} hours remaining',
      },
      older: {
        header: 'Older Auctions'
      },
      next: 'Next auction on {date}: {remaining} hours remaining',
      none: 'There are currently no auctions to display...',
      noneNext: 'The first auction will start on {date}!',
    },
    view: {
      minBid: 'Min Bid',
      myBid: 'My Bid',
      currentBid: 'Current Bid',
      ended: 'Ended',
      lock: {
        button: 'Lock NFT',
        confirm: 'Are you sure you want to lock your NFT in the contract?',
        info: 'If the auction ends with a bid, the NFT will be sold to the highest bidder and you will receive the EGLD proceeds minus the platform fee ({fee}%).',
        info2: 'If the auction ends without a bid, the NFT will be returned to your wallet.',
        success: 'NFT successfully locked in the contract!',
      },
      bid: {
        button: 'Bid',
        title: 'Place a bid',
        max: 'Max',
        confirm: 'Confirm Bid',
        error: {
          min: 'The amount needs to be greater than {min} EGLD',
          balance: 'The amount can\'t be greater than your current balance',
        },
        success: 'You successfully bid on the NFT!',
      },
      end: {
        button: 'End',
        confirm: 'Ending the auction will send the NFT (if any) and the bid EGLD (if any) to the appropriate addresses.',
        success: 'Auction was successfully ended and the NFT or EGLD is in your wallet!',
        error: 'An error has occurred while submitting the transaction...The auction might not exist'
      },
      locked: 'NFT Locked',
    },
    status: {
      upcoming: 'Upcoming',
      bidding: 'In progress',
      lock: 'Bidding ended, still time to lock NFTs',
      ended: 'Ended',
    },
  },
  staking: {
    rewards: {
      title: 'Current Token Rewards',
      token: 'Token',
      perDay: 'Reward Per Day',
      total: 'Total Rewards',
      end: 'Ends',
      amount: 'Remaining per Rare 1 NFT',
      percent: 'Percent per Rare 1 NFT',
      modifierTotal: 'Total Staked NFTs Modifier',
    },
    nfts: {
      title: 'Staked Top NFTs',
      unstaked: 'Unstaked Top NFTs',
    },
    stake: {
      button: 'Stake',
      confirm: 'Are you sure you want to stake this NFT? It will be locked for 5 days',
      success: 'NFT was successfully staked!',
      multiplier: 'Rewards Multiplier: <strong>{multiplier}x</strong>',
      none: 'You currently don\'t have any Top MEME NFTs to stake. {br} Check your {memes} page to see if you have any MEMEs that can be upgraded. {br} Alternatively you can get some from the {auction} page or from 3rd party marketplaces',
      noneMemes: 'Account Memes',
      noneAuction: 'Auction',
    },
    position: {
      unstake: 'Unstake',
      claim: 'Claim',
      confirm: 'Are you sure you want to unstake this NFT?',
      success: 'NFT was successfully unstaked!',
      successClaim: 'Rewards successfully claimed!',
      rewards: 'Current Rewards',
      tooltip: {
        locked: 'NFT will be unlocked {time}',
        unstake: 'Unstaking will also claim your rewards',
        claim: 'Claiming will also reset your unlock time',
      },
      none: 'You currently don\'t have any Top MEME NFTs staked...',
    },
  },
  referrals: {
    title: 'Referrals',
    info: 'Refer people to our platform and earn <strong class="text-info">extra votes</strong> for each referral. *',
    infoTooltip: 'gain 1 vote per person up to a maximum of 45 extra votes',
    info2: 'The person you refer will also get <strong class="text-info">15</strong> extra votes.',
    link: 'Just share the link below with someone and after they sign a transaction they will be counted as your referral!',
    copied: 'URL has been copied!',
    number: 'You currently have <strong class="text-info">{number}</strong> / <strong class="text-info">{max}</strong> referrals.',
    numberVotes: 'Which gives you <strong class="text-info">{extra}</strong> extra votes.',
    noMax: 'You first need to {stake} a Top MEME NFT before you can refer people',
    noMaxStake: 'Stake',
    referrer: 'You were referred by:',
    useReferer: {
      button: 'Confirm Referer',
      confirm: 'Are you sure you want to confirm {referer} as your referer? You will get 15 extra votes per period by doing so.',
      success: 'Referer was successfully set!',
    },
  },
  modal: {
    close: 'Close',
  },
  toast: {
    transactions: 'Transactions',
    sign: 'Sign Transactions',
    signMessage: 'Check your wallet to sign your transaction(s) and then come back to the site. <br> Make sure you have some EGLD to pay for gas fees!',
  },
  messages: {
    error: {
      transaction: 'An error has occurred while submitting the transaction...Please try again later.',
      general: 'An error has occurred...Please try again later.'
    },
    confirm: {
      ok: 'Continue',
      cancel: 'Cancel',
    }
  },
};
