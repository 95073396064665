export const ROUTES = {
  home: 'home',
  login: 'login',
  user: {
    memesCreated: 'userMemesCreated',
    memesWallet: 'userMemesWallet',
    topMemesWallet: 'userTopMemesWallet',
  },
  meme: {
    create: 'memeCreate',
    view: 'memeView',
    listTop: 'memeListTop',
    listTopOlder: 'memeListTopOlder',
    listOlder: 'memeListOlder',
  },
  auction: {
    view: 'auctionView',
    listCurrent: 'auctionCurrent',
    special: 'auctionSpecial',
    listOlder: 'auctionListOlder',
  },
  staking: 'staking',
  referrals: 'referrals',
};

export const PATHS_EN = {
  [ROUTES.home]: '/',

  [ROUTES.login]: '/login',

  [ROUTES.user.memesCreated]: '/profile/:address/memes/created',
  [ROUTES.user.memesWallet]: '/profile/:address/memes/wallet',
  [ROUTES.user.topMemesWallet]: '/profile/:address/top-memes/wallet',

  [ROUTES.meme.create]: '/meme/create',
  [ROUTES.meme.view]: '/meme/:nonce',
  [ROUTES.meme.listTop]: '/memes/top',
  [ROUTES.meme.listTopOlder]: '/memes/top/older/:period(\\d+)?',
  [ROUTES.meme.listOlder]: '/memes/older/:period(\\d+)?',

  [ROUTES.auction.view]: '/auction/:period/:nonce',
  [ROUTES.auction.listCurrent]: '/auction',
  [ROUTES.auction.special]: '/auction/special/:period(\\d+)?',
  [ROUTES.auction.listOlder]: '/auction/older/:period(\\d+)?',

  [ROUTES.staking]: '/staking',
  [ROUTES.referrals]: '/referrals',
};
