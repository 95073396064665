import memeVotingContract, { MEME_VOTING_CONSTANTS } from '@/contracts/memeVoting';
import { MEME_GETTERS } from '@/store/meme';
import generalClient from '@/api/general';

export const VOTE_GETTERS = {
  TEMP: 'voteTemp',
  USER_REMAINING: 'voteUserRemaining',
};

export const VOTE_MUTATIONS = {
  TEMP: 'voteTemp',
  USER_REMAINING: 'voteUserRemaining',
  USER_REMAINING_REMOVE: 'voteUserRemainingRemove',
  REMOVE_TEMP: 'voteRemoveTemp',
};

export const VOTE_ACTIONS = {
  GET_USER_REMAINING: 'voteGetUserRemaining',
  ADD_TEMP: 'voteAddTemp',
  SUBMIT: 'voteSubmit',
}

export const voteStore = {
  state: () => ({
    temp: [],
    userRemaining: null,
  }),
  getters: {
    [VOTE_GETTERS.TEMP](state) {
      return state.temp;
    },
    [VOTE_GETTERS.USER_REMAINING](state) {
      return state.userRemaining;
    },
  },
  mutations: {
    [VOTE_MUTATIONS.TEMP](state, temp) {
      state.temp = temp;
    },
    [VOTE_MUTATIONS.USER_REMAINING](state, userRemaining) {
      state.userRemaining = userRemaining;
    },
    [VOTE_MUTATIONS.USER_REMAINING_REMOVE](state) {
      state.userRemaining = state.userRemaining - state.temp.length;
    },
    [VOTE_MUTATIONS.REMOVE_TEMP](state, nonce) {
      state.temp = state.temp.filter(n => n !== nonce);
    },
  },
  actions: {
    async [VOTE_ACTIONS.GET_USER_REMAINING]({ commit, getters }, address) {
      const currentPeriod = getters[MEME_GETTERS.CURRENT_PERIOD];
      // eslint-disable-next-line prefer-const
      let { period, votes } = await generalClient.addressVotes(address);

      if (currentPeriod !== period) {
        const extraVotes = await generalClient.addressExtraVotes(address);

        votes = MEME_VOTING_CONSTANTS.VOTES_PER_ADDRESS_PER_PERIOD + extraVotes;
      }

      commit(VOTE_MUTATIONS.USER_REMAINING, votes);
    },
    [VOTE_ACTIONS.ADD_TEMP]({ commit, getters }, nonce) {
      const temp = getters[VOTE_GETTERS.TEMP];

      // Limit votes to 20 or to the number of user remaining votes
      if (temp.length >= getters[VOTE_GETTERS.USER_REMAINING] || temp.length >= 20) {
        return false;
      }

      commit(VOTE_MUTATIONS.TEMP, [...temp, nonce].sort((a, b) => a - b));

      return true;
    },
    async [VOTE_ACTIONS.SUBMIT]({ getters }, { accountElrond, signature }) {
      return await memeVotingContract.voteMemes(accountElrond, signature, getters[VOTE_GETTERS.TEMP]);
    },
  },
};
