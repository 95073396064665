import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal fade",
  style: {"z-index":"3000"},
  id: "modal-confirm",
  tabindex: "-1",
  "aria-labelledby": "modalMessage",
  "aria-hidden": "true",
  ref: "confirmModalRef"
}
const _hoisted_2 = { class: "modal-dialog" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = {
  key: 0,
  class: "modal-title",
  id: "modalMessage"
}
const _hoisted_6 = { class: "modal-body pb-2" }
const _hoisted_7 = { class: "alert alert-warning" }
const _hoisted_8 = { class: "modal-footer py-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.confirmModal)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              ($setup.confirmModal && $setup.confirmModal.title && _ctx.$te($setup.confirmModal.title))
                ? (_openBlock(), _createElementBlock("h5", _hoisted_5, _toDisplayString(_ctx.$t($setup.confirmModal.title)), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t($setup.confirmModal.message, $setup.confirmModal.messageParams || {})), 1),
              ($setup.confirmModal.text)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.confirmModal.text, (text) => {
                    return (_openBlock(), _createElementBlock("p", { key: text }, _toDisplayString(text), 1))
                  }), 128))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("button", {
                class: _normalizeClass('btn btn-' + ($setup.confirmModal && $setup.confirmModal.button ? $setup.confirmModal.button : 'danger')),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.confirm && $setup.confirm(...args)))
              }, _toDisplayString(_ctx.$t('messages.confirm.ok')), 3),
              _createElementVNode("button", {
                class: "btn btn-secondary",
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.modal.hide()))
              }, _toDisplayString(_ctx.$t('messages.confirm.cancel')), 1)
            ])
          ])
        ])
      ], 512))
    : _createCommentVNode("", true)
}