import {
  Account, Address,
  AddressValue,
  BigUIntValue,
  BytesValue,
  ContractFunction,
  Struct,
  TokenPayment,
  Transaction,
  TransactionPayload,
  U64Value,
  VariadicValue,
} from '@multiversx/sdk-core';
import elrondHelper from "@/helpers/elrond";
import BLOCKCHAIN from "@/constants/blockchain";
import { BigNumber } from "bignumber.js";
import BaseContract from '@/contracts/baseContract';

export interface AUCTION {
  nonce: number;
  minBid: number;
  currentBid: number;
  currentWinner: string;
  bidCutPercentage: number;
  originalOwner: string;
  ended: boolean;
  topNonce: number;
}

export const MEME_AUCTION_CONSTANTS = {
  ERRORS: {
    NOT_EXIST: "Auction does not exist",
    CANT_UPGRADE: "Nft can't be upgraded",
  },
  BID_TIME: 345600, // 4 days in seconds (time users can bid on NFTs)
  AUCTION_TIME: 432000, // 5 days in seconds (time the owner of the NFT has for locking it)
};

export const MEME_AUCTION_FUNCTIONS = {
  BID: "bid",
  END_AUCTION: "end_auction",
  UPGRADE_TOKEN: "upgrade_token",
};

class MemeAuctionContract extends BaseContract {
  constructor() {
    super(BLOCKCHAIN.MEMES_AUCTION_CONTRACT, 'memes-auction', 'MemesAuction');
  }

  // Endpoints
  async lockToken(account: Account, period: number, nonce: number, isTopMeme: boolean): Promise<Transaction> {
    const func = new ContractFunction("ESDTNFTTransfer");
    const payload = TransactionPayload.contractCall()
      .setFunction(func)
      .setArgs([
        BytesValue.fromUTF8(isTopMeme ? BLOCKCHAIN.TOP_NFT_TOKEN_IDENTIFIER : BLOCKCHAIN.NFT_TOKEN_IDENTIFIER),
        new U64Value(new BigNumber(nonce)),
        new BigUIntValue(new BigNumber(1)),
        new AddressValue(this.contract.getAddress()),
        BytesValue.fromUTF8("lock_token"),
        new U64Value(new BigNumber(period)),
      ])
      .build();

    const transaction = new Transaction({
      sender: account.address,
      receiver: account.address,
      value: TokenPayment.egldFromAmount(0),
      gasLimit: 6000000,
      data: payload,
      nonce: account.nonce,
      chainID: elrondHelper.networkConfig.ChainID,
    });

    return await elrondHelper.sendTransaction(transaction, account);
  }

  async bid(account: Account, amount: number, period: number, nonce: number): Promise<Transaction> {
    const func = new ContractFunction(MEME_AUCTION_FUNCTIONS.BID);
    const payload = TransactionPayload.contractCall()
      .setFunction(func)
      .setArgs([new U64Value(new BigNumber(period)), new U64Value(new BigNumber(nonce))])
      .build();

    const transaction = new Transaction({
      sender: account.address,
      receiver: this.contract.getAddress(),
      value: TokenPayment.egldFromAmount(amount),
      gasLimit: 6000000,
      data: payload,
      nonce: account.nonce,
      chainID: elrondHelper.networkConfig.ChainID,
    });

    return await elrondHelper.sendTransaction(transaction, account);
  }

  async end(account: Account, period: number, nonce: number): Promise<Transaction> {
    const func = new ContractFunction(MEME_AUCTION_FUNCTIONS.END_AUCTION);
    const payload = TransactionPayload.contractCall()
      .setFunction(func)
      .setArgs([new U64Value(new BigNumber(period)), new U64Value(new BigNumber(nonce))])
      .build();

    const transaction = new Transaction({
      sender: account.address,
      receiver: this.contract.getAddress(),
      value: TokenPayment.egldFromAmount(0),
      gasLimit: 8000000, // 6000000 from Mandos + 2000000 just in case
      data: payload,
      nonce: account.nonce,
      chainID: elrondHelper.networkConfig.ChainID,
    });

    return await elrondHelper.sendTransaction(transaction, account);
  }

  async upgradeToken(account: Account, nonce: number, isTopMeme: boolean): Promise<Transaction> {
    const func = new ContractFunction("ESDTNFTTransfer");
    const payload = TransactionPayload.contractCall()
      .setFunction(func)
      .setArgs([
        BytesValue.fromUTF8(isTopMeme ? BLOCKCHAIN.TOP_NFT_TOKEN_IDENTIFIER : BLOCKCHAIN.NFT_TOKEN_IDENTIFIER),
        new U64Value(new BigNumber(nonce)),
        new BigUIntValue(new BigNumber(1)),
        new AddressValue(this.contract.getAddress()),
        BytesValue.fromUTF8(MEME_AUCTION_FUNCTIONS.UPGRADE_TOKEN),
      ])
      .build();

    const transaction = new Transaction({
      sender: account.address,
      receiver: account.address,
      value: TokenPayment.egldFromAmount(0),
      gasLimit: 8000000,
      data: payload,
      nonce: account.nonce,
      chainID: elrondHelper.networkConfig.ChainID,
    });

    return await elrondHelper.sendTransaction(transaction, account);
  }

  // Views
  async periodAuctionsMemesAll(period: string): Promise<AUCTION[]> {
    const query = await this.contract.createQuery({
      func: new ContractFunction("period_auctions_memes_all"),
      args: [new U64Value(new BigNumber(period))],
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = (await this.getContractAbi()).getEndpoint("period_auctions_memes_all");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return (firstValue as VariadicValue)
      .getItems()
      .map((result: Struct) =>
        this.mapAuction(result.getFieldValue("nonce").toNumber(), result.getFieldValue("auction"))
      );
  }

  async periodMemeAuction(period: string, nonce: number): Promise<AUCTION> {
    const query = await this.contract.createQuery({
      func: new ContractFunction("period_meme_auction"),
      args: [new U64Value(new BigNumber(period)), new U64Value(new BigNumber(nonce))],
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = (await this.getContractAbi()).getEndpoint("period_meme_auction");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return this.mapAuction(nonce, firstValue.valueOf());
  }

  private mapAuction(nonce: number, result: any): AUCTION {
    return {
      nonce,
      minBid: result.min_bid.toNumber(),
      currentBid: result.current_bid.toNumber(),
      currentWinner: result.current_winner.toString(),
      bidCutPercentage: result.bid_cut_percentage.toNumber(),
      originalOwner: result.original_owner.toString(),
      ended: result.ended,
      topNonce: result.top_nonce.toNumber(),
    };
  }
}

const memeAuctionContract = new MemeAuctionContract();

export default memeAuctionContract;
