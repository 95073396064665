export const pagesEN = {
  home: {
    help: 'Learn More',
    memes: 'Do you like <span class="text-info">MEMEs</span>?',
    nfts: 'Do you like <span class="text-info">NFTs</span>?',
    headline: 'Become an <em class="text-info">xMemer</em> today and <em class="text-info">NFT your MEME</em>!',
    create: 'Create memes and <strong><em>own</em></strong> them as <strong><em>NFTs</em></strong>!',
    vote: 'Vote for your favourites to decide which ones are the best.',
    auction: 'Every week, the <em>Top 10</em> most voted memes enter into an <strong><em>auction</em></strong>!',
    docs: 'For more info check out our <a href="https://docs.xmemers.com" target="_blank" class="ms-1 btn btn-sm btn-outline-light">docs <i class="bi bi-box-arrow-up-right"></i></a>',
  },
  auction: {
    memes: 'Bid on <soan class="text-info">MEMEs</soan> to own them as <span class="text-info">NFTs</span>!',
    stake: 'Stake the <em class="text-info">NFTs</em> for rewards & <em class="text-info">exclusive benefits</em>!',
    benefits: 'Get access to a referral system & a share of the platform\'s profits depending on the rank of your NFT.',
    bidding: 'Only <em>10 NFTs</em> are available <em>every 4 weeks</em>. Bidding lasts for <strong>4 days</strong> (96 hours).',
    locking: 'NFTs can be locked in an auction by the owner, in the first <strong>5 days</strong> (120 hours).',
  },
  notFound: {
    title: '404: Page not found...',
    subtitle: 'There has been an error while trying to display this page',
    link: 'Back to Homepage',
  },
};
