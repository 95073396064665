import GENERAL from '@/constants/general';
import { MEME_AUCTION_CONSTANTS } from '@/contracts/memeAuction';

const generalHelper: any = {
  getAuctionStatus(period: number) {
    const now = Date.now() / 1000;

    if (now < period) {
      return GENERAL.AUCTION_STATUSES.UPCOMING;
    }

    const bidTimeTill = (period + MEME_AUCTION_CONSTANTS.BID_TIME);
    const auctionTimeTill = period + MEME_AUCTION_CONSTANTS.AUCTION_TIME;

    return now < bidTimeTill ? GENERAL.AUCTION_STATUSES.BIDDING
      : now < auctionTimeTill ? GENERAL.AUCTION_STATUSES.LOCK
      : GENERAL.AUCTION_STATUSES.ENDED;
  }
};

export default generalHelper;
