import elrondApiHelper, { NFT_POST, TOP_NFT_POST } from '@/helpers/elrondApi';
import memeVotingContract, { MEME_VOTES } from '@/contracts/memeVoting';
import storeHelper, { LIST } from '@/helpers/store';
import generalClient from '@/api/general';
import memeClient from '@/api/meme';
import BLOCKCHAIN from '@/constants/blockchain';

export const MEME_GETTERS = {
  CURRENT: 'memeCurrent',
  LIST: 'memeList',
  CURRENT_PERIOD: 'memeCurrentPeriod',
  ALL_PERIODS: 'memeAllPeriods',
  RESTRICTED: 'memeRestricted',
};

export const MEME_MUTATIONS = {
  CURRENT: 'memeCurrent',
  LIST: 'memeList',
  CURRENT_PERIOD: 'memeCurrentPeriod',
  ALL_PERIODS: 'memeAllPeriods',
  ADD_VOTES: 'memeAddVotes',
  RESTRICTED: 'memeRestricted',
};

export const MEME_ACTIONS = {
  GET: 'memeGet',
  GET_VOTES_PERIOD: 'memeGetVotesPeriod',
  GET_VOTES_ALL: 'memeGetVotesAll',

  GET_RARITY: 'memeGetRarity',

  GET_TOP_MEME: 'memeGetTopMeme',

  GET_LIST: 'memeGetList',
  GET_LIST_TOP: 'memeGetListTop',
  GET_LIST_OLDER: 'memeGetListOlder',
  GET_CURRENT_PERIOD: 'memeGetCurrentPeriod',
  GET_ALL_PERIODS: 'memeGetAllPeriods',

  GET_RESTRICTED: 'memeGetRestricted',
};

export interface NFT_POST_FULL extends NFT_POST {
  votes: number;
}

export interface NFT_POST_TOP extends NFT_POST_FULL {
  actualRarity: number;
}

export interface NFT_POST_VIEW extends NFT_POST_TOP {
  allVotes: number;
}

interface MEME_STORE_STATE {
  current?: NFT_POST_VIEW;
  list?: LIST<NFT_POST_FULL | NFT_POST_TOP>;
  currentPeriod?: number;
  allPeriods?: number[];
  restricted: number[];
}

export const memeStore = {
  state: (): MEME_STORE_STATE => ({
    current: null,
    list: null,
    currentPeriod: null,
    allPeriods: null,
    restricted: [],
  }),
  getters: {
    [MEME_GETTERS.CURRENT](state) {
      return state.current;
    },
    [MEME_GETTERS.LIST](state) {
      return state.list;
    },
    [MEME_GETTERS.CURRENT_PERIOD](state) {
      return state.currentPeriod;
    },
    [MEME_GETTERS.ALL_PERIODS](state) {
      return state.allPeriods;
    },
    [MEME_GETTERS.RESTRICTED](state) {
      return state.restricted;
    },
  },
  mutations: {
    [MEME_MUTATIONS.CURRENT](state, nft) {
      state.current = nft;
    },
    [MEME_MUTATIONS.LIST](state, nfts) {
      state.list = nfts;
    },
    [MEME_MUTATIONS.CURRENT_PERIOD](state, period) {
      state.currentPeriod = period;
    },
    [MEME_MUTATIONS.ALL_PERIODS](state, allPeriods) {
      state.allPeriods = allPeriods;
    },
    [MEME_MUTATIONS.ADD_VOTES](state: MEME_STORE_STATE, votes) {
      if (state.current) {
        const meme = state.current;
        const newVotes = votes.filter((nonce) => nonce === meme.nonce);

        state.current = {
          ...meme,
          votes: newVotes.reduce((acc) => acc + 1, meme.votes),
          allVotes: newVotes.reduce((acc) => acc + 1, meme.allVotes),
        };
      }

      if (state.list) {
        state.list = {
          ...state.list,
          items: state.list.items.map((meme) => {
            return {
              ...meme,
              votes: votes.filter((nonce) => nonce === meme.nonce).reduce((acc) => acc + 1, meme.votes),
            };
          }),
        };
      }
    },
    [MEME_MUTATIONS.RESTRICTED](state, restricted) {
      state.restricted = restricted;
    },
  },
  actions: {
    async [MEME_ACTIONS.GET]({ dispatch }, nonce): Promise<NFT_POST_VIEW> {
      let nft = await dispatch(MEME_ACTIONS.GET_TOP_MEME, nonce);
      if (!nft) {
        nft = await elrondApiHelper.getNft(nonce);
      }

      const votes = await dispatch(MEME_ACTIONS.GET_VOTES_ALL, nonce);
      const actualRarity = await dispatch(MEME_ACTIONS.GET_RARITY, nonce);

      return {
        ...nft,
        ...votes,
        actualRarity,
      };
    },
    async [MEME_ACTIONS.GET_VOTES_PERIOD](_, { nonce, period }): Promise<number> {
      return await memeClient.memeVotes(nonce, period);
    },
    async [MEME_ACTIONS.GET_VOTES_ALL](
      _,
      nonce
    ): Promise<{
      votes: number;
      allVotes: number;
    }> {
      return await memeClient.memeVotesAll(nonce);
    },
    async [MEME_ACTIONS.GET_RARITY](_, nonce): Promise<number> {
      return await memeClient.memeRarity(nonce);
    },
    async [MEME_ACTIONS.GET_TOP_MEME](_, nonce): Promise<TOP_NFT_POST> {
      const topMemeNonce = await memeClient.memeTopMeme(nonce);

      if (!topMemeNonce) {
        return null;
      }

      return (await elrondApiHelper.getNft(topMemeNonce, BLOCKCHAIN.TOP_NFT_TOKEN_IDENTIFIER)) as TOP_NFT_POST;
    },
    async [MEME_ACTIONS.GET_LIST]({ commit, getters }, { page }) {
      const total = await memeVotingContract.currentPeriodLen();
      const response = await memeVotingContract.currentPeriodMemesLatest(page);

      await commitMemesFromNonces(commit, getters, response, page, total);
    },
    async [MEME_ACTIONS.GET_LIST_TOP]({ commit }, { period }) {
      const response = await memeVotingContract.periodTopMemes(period);

      const { memes } = await getMemesFromNonces(response, response.length, true);

      storeHelper.commitList(commit, MEME_MUTATIONS.LIST, memes, 0, memes.length);
    },
    async [MEME_ACTIONS.GET_LIST_OLDER]({ commit, getters }, { period, page, append = false }) {
      const total = await memeVotingContract.periodLen(period);
      const response = await memeVotingContract.periodMemesLatest(period, page);

      await commitMemesFromNonces(commit, getters, response, page, total, append);
    },
    async [MEME_ACTIONS.GET_CURRENT_PERIOD]({ commit }) {
      commit(MEME_MUTATIONS.CURRENT_PERIOD, await generalClient.currentPeriod());
    },
    async [MEME_ACTIONS.GET_ALL_PERIODS]({ commit, getters }) {
      if (null !== getters[MEME_GETTERS.ALL_PERIODS]) {
        return;
      }

      commit(MEME_MUTATIONS.ALL_PERIODS, await generalClient.periods());
    },
    async [MEME_ACTIONS.GET_RESTRICTED]({ commit }) {
      commit(MEME_MUTATIONS.RESTRICTED, await memeClient.memesRestricted());
    },
  },
};

async function commitMemesFromNonces(
  commit,
  getters,
  response: MEME_VOTES[],
  page: number,
  total: number,
  append: boolean = false
) {
  const { memes, newTotal } = await getMemesFromNonces(response, total);

  if (append) {
    const newMemes = [...getters[MEME_GETTERS.LIST].items, ...memes];

    storeHelper.commitList(
      commit,
      MEME_MUTATIONS.LIST,
      newMemes,
      page,
      getters[MEME_GETTERS.LIST].total + (page === 0 ? newTotal : 0)
    );

    return;
  }

  if (0 === page) {
    storeHelper.commitList(commit, MEME_MUTATIONS.LIST, memes, page, newTotal);

    return;
  }

  const newMemes = [...getters[MEME_GETTERS.LIST].items, ...memes];

  storeHelper.commitList(commit, MEME_MUTATIONS.LIST, newMemes, page, newTotal);
}

async function getMemesFromNonces(response: MEME_VOTES[], total: number, forTopMemes: boolean = false) {
  const allNonces = response.map(({ nonce }) => nonce);

  const { topMemeNonces, nfts, topNfts } = await getMemesAndTopMemes(allNonces);

  let startingRarity = response.length + 1;

  const memes = response.map(({ nonce, votes }): NFT_POST_FULL => {
    startingRarity--;

    let nft = null;
    if (nonce in topMemeNonces && topMemeNonces[nonce] in topNfts) {
      nft = topNfts[topMemeNonces[nonce]];
    }

    if (nonce in nfts) {
      nft = nfts[nonce];
    }

    if (!nft) {
      total--;
      console.error("Couldn't retrieve nft for nonce: ", nonce);

      return null;
    }

    const data = {
      ...nft,
      votes,
    };

    if (forTopMemes) {
      data.actualRarity = startingRarity;
    }

    return data;
  });

  return { memes: memes.filter((result) => result !== null), newTotal: total };
}

async function getMemesAndTopMemes(allNonces: number[]) {
  const topMemeNonces = await memeClient.memesTopMeme(allNonces);
  Object.keys(topMemeNonces).forEach((nonce) => {
    const index = allNonces.indexOf(Number.parseInt(nonce));
    if (-1 !== index) {
      allNonces.splice(index, 1);
    }
  });

  const nfts = await elrondApiHelper.getNfts(allNonces);
  const topNfts = await elrondApiHelper.getNfts(Object.values(topMemeNonces), BLOCKCHAIN.TOP_NFT_TOKEN_IDENTIFIER);

  return { topMemeNonces, nfts, topNfts };
}
