import { createStore } from "vuex"

import { auctionStore } from './auction';
import { memeStore } from './meme';
import { userStore } from './user';
import { voteStore } from './vote';
import { stakingStore } from '@/store/staking';
import { referralsStore } from '@/store/referrals';

export const BASE_GETTERS = {
  MESSAGE_MODAL: 'messageModal',
  CONFIRM_MODAL: 'confirmModal',
  TRANSACTION_TOAST: 'transactionToast',
  SIGN_TRANSACTION_TOAST: 'signTransactionToast',
};

export const BASE_MUTATIONS = {
  MESSAGE_MODAL: 'messageModal',
  CLOSE_MESSAGE_MODAL: 'closeMessageModal',
  CONFIRM_MODAL: 'confirmModal',
  CLOSE_CONFIRM_MODAL: 'closeConfirmModal',
  TRANSACTION_TOAST: 'transactionToast',
  REMOVE_TRANSACTION_TOAST: 'removeTransactionToast',
  RESET_TRANSACTION_TOAST: 'resetTransactionToast',
  SIGN_TRANSACTION_TOAST: 'signTransactionToast',
};

const store = createStore({
  state: () => ({
    messageModal: null,
    confirmModal: null,
    transactionToast: {},
    signTransactionsToast: null,
  }),
  getters: {
    [BASE_GETTERS.MESSAGE_MODAL](state) {
      return state.messageModal;
    },
    [BASE_GETTERS.CONFIRM_MODAL](state) {
      return state.confirmModal;
    },
    [BASE_GETTERS.TRANSACTION_TOAST](state) {
      return state.transactionToast;
    },
    [BASE_GETTERS.SIGN_TRANSACTION_TOAST](state) {
      return state.signTransactionsToast;
    },
  },
  mutations: {
    [BASE_MUTATIONS.MESSAGE_MODAL](state, messageModal) {
      state.messageModal = messageModal;
    },
    [BASE_MUTATIONS.CLOSE_MESSAGE_MODAL](state) {
      state.messageModal = null;
    },
    [BASE_MUTATIONS.CONFIRM_MODAL](state, confirmModal) {
      state.confirmModal = confirmModal;
    },
    [BASE_MUTATIONS.CLOSE_CONFIRM_MODAL](state) {
      state.confirmModal = null;
    },
    [BASE_MUTATIONS.TRANSACTION_TOAST](state, { hash, status }) {
      state.transactionToast[hash] = status;
    },
    [BASE_MUTATIONS.REMOVE_TRANSACTION_TOAST](state, hash) {
      delete state.transactionToast[hash];
    },
    [BASE_MUTATIONS.RESET_TRANSACTION_TOAST](state) {
      state.transactionToast = {};
    },
    [BASE_MUTATIONS.SIGN_TRANSACTION_TOAST](state, value = true) {
      state.signTransactionsToast = value;
    }
  },
  actions: {},
  modules: {
    auction: auctionStore,
    meme: memeStore,
    referrals: referralsStore,
    staking: stakingStore,
    user: userStore,
    vote: voteStore,
  }
});

export default store;