import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "accordion accordion-flush" }
const _hoisted_2 = { class: "accordion-item bg-transparent" }
const _hoisted_3 = {
  class: "accordion-header",
  id: "flush-headingOne"
}
const _hoisted_4 = {
  class: "collapsed bg-transparent text-white btn btn-outline-light rounded-pill font-karnivore-black",
  type: "button",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#flush-collapseOne",
  "aria-expanded": "false",
  "aria-controls": "flush-collapseOne"
}
const _hoisted_5 = {
  id: "flush-collapseOne",
  class: "accordion-collapse collapse text-white",
  "aria-labelledby": "flush-headingOne",
  "data-bs-parent": "#accordionFlushExample"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, [
        _createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.$t('pages.home.help')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}