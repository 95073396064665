
import ipfsHelper from "@/helpers/ipfs";
import { ROUTES } from "@/router/constants";
import { useStore } from "vuex";
import { VOTE_ACTIONS, VOTE_GETTERS, VOTE_MUTATIONS } from "@/store/vote";
import { computed, PropType, ref } from "vue";
import { BASE_MUTATIONS } from "@/store";
import { USER_GETTERS } from "@/store/user";
import { MEME_GETTERS, NFT_POST_FULL, NFT_POST_VIEW } from "@/store/meme";
import formatterHelper from "@/helpers/formatter";
import CreatorTipModal from "@/pages/user/components/CreatorTipModal.vue";
import { useRoute, useRouter } from 'vue-router';

export default {
  name: "MemePost",
  components: { CreatorTipModal },
  props: {
    meme: Object as PropType<NFT_POST_FULL | NFT_POST_VIEW>,
    view: Boolean,
    canVote: Boolean,
    noVotes: Boolean,
  },
  created() {
    this.ROUTES = ROUTES;
    this.ipfsHelper = ipfsHelper;
    this.formatterHelper = formatterHelper;
  },
  setup(props) {
    const store = useStore();
    const totalVoted = computed(() => {
      return store.getters[VOTE_GETTERS.TEMP].filter((nonce) => nonce === props.meme.nonce).reduce((acc) => acc + 1, 0);
    });
    const address = computed(() => store.getters[USER_GETTERS.ADDRESS_ELROND]);
    const isRestricted = ref(store.getters[MEME_GETTERS.RESTRICTED].includes(props.meme.nonce));

    const route = useRoute();
    const router = useRouter();

    const voteAddTemp = async () => {
      if (!props.canVote) {
        return;
      }

      if (!address.value) {
        await router.push({ name: ROUTES.login, query: { redirect: route.fullPath } });

        return;
      }

      if (!await store.dispatch(VOTE_ACTIONS.ADD_TEMP, props.meme.nonce)) {
        store.commit(BASE_MUTATIONS.MESSAGE_MODAL, {
          variant: "warning",
          message: "meme.vote.warning",
        });
      }
    };

    const voteRemoveTemp = (event) => {
      event.stopPropagation();

      store.commit(VOTE_MUTATIONS.REMOVE_TEMP, props.meme.nonce);
    };

    const tipModal = ref(false);

    return {
      voteAddTemp,
      voteRemoveTemp,
      totalVoted,
      address,
      tipModal,
      isRestricted,
    };
  },
};
