const ENV = {
  ELROND_NET: process.env.VUE_APP_ELROND_NET,

  MICROSERVICE_URL: process.env.VUE_APP_MICROSERVICE_URL,

  NFT_TOKEN_IDENTIFIER: process.env.VUE_APP_NFT_TOKEN_IDENTIFIER,
  CONTRACT_MEME_VOTING: process.env.VUE_APP_CONTRACT_MEME_VOTING,

  TOP_NFT_TOKEN_IDENTIFIER: process.env.VUE_APP_TOP_NFT_TOKEN_IDENTIFIER,
  CONTRACT_MEME_AUCTION: process.env.VUE_APP_CONTRACT_MEME_AUCTION,

  CAPTCHA_ID: process.env.VUE_APP_CAPTCHA_ID,

  PERIOD_TIME: Number.parseInt(process.env.VUE_APP_PERIOD_TIME),

  META_STAKING_TOKEN_IDENTIFIER: process.env.VUE_APP_META_STAKING_TOKEN_IDENTIFIER,
  CONTRACT_MEME_STAKING: process.env.VUE_APP_CONTRACT_MEME_STAKING,

  TIP_TOKENS: process.env.VUE_APP_TIP_TOKENS,
  STAKING_TOKENS: process.env.VUE_APP_STAKING_TOKENS,

  IS_DEVELOPMENT: process.env.NODE_ENV === 'development',
};

export default ENV;