import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "d-grid col-12 col-md-6 col-lg-4 mx-auto my-3" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!

  return ($props.list.items.length < $props.list.total)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "btn btn-outline-secondary",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($props.loadMore && $props.loadMore(...args))),
            disabled: $props.loading
          }, [
            _createVNode(_component_Loader, { loading: $props.loading }, null, 8, ["loading"]),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('meme.list.loadMore')), 1)
          ], 8, _hoisted_3)
        ])
      ]))
    : _createCommentVNode("", true)
}