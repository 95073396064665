const STORAGE_KEYS = {
  EXTENSION_LOGIN: 'extension_login',
  MAIAR_LOGIN: 'maiar_login',
  LEDGER_LOGIN: 'ledger_login',

  BACKEND_LOGIN_TOKEN: 'backend_login_token',
  BACKEND_ACCESS_TOKEN: 'backend_access_token',

  ADDRESS_LAST_MEME_TIME: 'address_last_meme_time',

  REFERER: 'referer_',
};

const storageHelper = {
  // Default ttl for an item in storage is 7 days
  setItem: (key, item, ttl = 604800 * 1000) => {
    const expires = Date.now() + ttl;

    sessionStorage.setItem(
      key,
      JSON.stringify({
        expires,
        data: item,
      })
    );
  },
  setLocalItem: (key, item) => {
    localStorage.setItem(key, JSON.stringify(item));
  },
  getItem: (key) => {
    const item = sessionStorage.getItem(key);
    if (!item) {
      return null;
    }

    const deserializedItem = JSON.parse(item);
    if (!deserializedItem) {
      return null;
    }

    if (!deserializedItem?.expires || !deserializedItem?.data) {
      return null;
    }

    const expired = Date.now() >= deserializedItem.expires;
    if (expired) {
      sessionStorage.removeItem(key);

      return null;
    }

    return deserializedItem.data;
  },
  getLocalItem: (key) => {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }

    const deserializedItem = JSON.parse(item);
    if (!deserializedItem) {
      return null;
    }

    return deserializedItem;
  },
  deleteItem: (key) => {
    sessionStorage.removeItem(key);
  },
  deleteLocalItem: (key) => {
    localStorage.removeItem(key);
  },

  setExtensionLogin(address) {
    storageHelper.clearLogins();

    storageHelper.setItem(STORAGE_KEYS.EXTENSION_LOGIN, address);
  },
  getExtensionLogin() {
    return storageHelper.getItem(STORAGE_KEYS.EXTENSION_LOGIN);
  },

  setMaiarLogin(address) {
    storageHelper.clearLogins();

    storageHelper.setItem(STORAGE_KEYS.MAIAR_LOGIN, address);
  },
  getMaiarLogin() {
    return storageHelper.getItem(STORAGE_KEYS.MAIAR_LOGIN);
  },

  setLedgerLogin(addressIndex, address) {
    storageHelper.clearLogins();

    storageHelper.setItem(STORAGE_KEYS.LEDGER_LOGIN, { addressIndex, address });
  },
  getLedgerLogin() {
    return storageHelper.getItem(STORAGE_KEYS.LEDGER_LOGIN);
  },

  clearLogins(includeBackend = false, address = null) {
    storageHelper.deleteItem(STORAGE_KEYS.EXTENSION_LOGIN);
    storageHelper.deleteItem(STORAGE_KEYS.MAIAR_LOGIN);
    storageHelper.deleteItem(STORAGE_KEYS.LEDGER_LOGIN);

    if (includeBackend) {
      storageHelper.deleteItem(STORAGE_KEYS.ADDRESS_LAST_MEME_TIME);
      storageHelper.deleteItem(STORAGE_KEYS.BACKEND_ACCESS_TOKEN);

      if (address) {
        storageHelper.deleteLocalItem(STORAGE_KEYS.REFERER + address);
      }
    }
  },

  setBackendLoginToken(token) {
    storageHelper.setItem(STORAGE_KEYS.BACKEND_LOGIN_TOKEN, token, 600 * 1000); // 10 minutes
  },
  getBackendLoginToken() {
    return storageHelper.getItem(STORAGE_KEYS.BACKEND_LOGIN_TOKEN);
  },

  setBackendAccessToken(token) {
    storageHelper.deleteItem(STORAGE_KEYS.BACKEND_LOGIN_TOKEN);

    storageHelper.setItem(STORAGE_KEYS.BACKEND_ACCESS_TOKEN, token);
  },
  getBackendAccessToken() {
    return storageHelper.getItem(STORAGE_KEYS.BACKEND_ACCESS_TOKEN);
  },

  setAddressLastMemeTime(time) {
    if (!time) {
      storageHelper.deleteItem(STORAGE_KEYS.ADDRESS_LAST_MEME_TIME);

      return;
    }

    storageHelper.setItem(STORAGE_KEYS.ADDRESS_LAST_MEME_TIME, time);
  },
  getAddressLastMemeTime() {
    return storageHelper.getItem(STORAGE_KEYS.ADDRESS_LAST_MEME_TIME);
  },

  setReferer(address: string, referer) {
    if (address) {
      storageHelper.deleteLocalItem(STORAGE_KEYS.REFERER);
    }

    storageHelper.setLocalItem(STORAGE_KEYS.REFERER + address, referer);
  },
  getReferer(address: string) {
    return storageHelper.getLocalItem(STORAGE_KEYS.REFERER + address);
  },
};

export default storageHelper;
