
import BLOCKCHAIN from '@/constants/blockchain';
import { computed, onMounted, ref, watch } from 'vue';
import ELROND from '@/constants/elrond';
import { useStore } from 'vuex';
import { USER_ACTIONS, USER_GETTERS } from '@/store/user';
import formatterHelper from '@/helpers/formatter';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'TokenSelect',
  props: {
    modelValue: String,
    disabled: Boolean,
    notBalance: Boolean,
    customTokens: Object,
  },
  emits: ['update:modelValue'],
  created() {
    this.TIP_TOKENS = BLOCKCHAIN.TIP_TOKENS;
    this.formatterHelper = formatterHelper;
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const value = ref(props.modelValue);

    const getImgUrl = (label) => {
      if (ELROND.EGLD_TOKEN === label) {
        return require(`../assets/images/tokens/${label}.png`);
      }

      return ELROND.TOKEN_LOGO.replace('{TOKEN}', BLOCKCHAIN.TIP_TOKENS[label].logo);
    };

    const store = useStore();

    const accountElrond = computed(() => store.getters[USER_GETTERS.ACCOUNT_ELROND]);
    const tokenBalance = computed(() => store.getters[USER_GETTERS.TOKENS][value.value]);

    const getTokenBalance = (token) =>
      store.dispatch(USER_ACTIONS.GET_TOKEN_BALANCE, {
        token,
        decimals: BLOCKCHAIN.TIP_TOKENS[token].decimals,
      });

    watch(accountElrond, (newValue, oldValue) => {
      if (oldValue || !newValue) {
        return;
      }

      getTokenBalance(value.value);
    });

    onMounted(() => {
      if (route.query.token && Object.keys(BLOCKCHAIN.TIP_TOKENS).includes(route.query.token as string)) {
        value.value = route.query.token as string;
      } else {
        router.replace({ ...route, query: { token: value.value } });
      }
    });

    watch(value, (newValue) => {
      router.replace({ ...route, query: { token: value.value } });

      context.emit('update:modelValue', newValue);

      getTokenBalance(newValue);
    });

    onMounted(() => getTokenBalance(value.value));

    return {
      value,
      getImgUrl,
      accountElrond,
      tokenBalance,
    };
  },
};
