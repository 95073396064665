import {
  AddressType,
  BigUIntType,
  BinaryCodec,
  FieldDefinition,
  Struct,
  StructType,
  U64Type,
  U8Type,
} from '@multiversx/sdk-core';
import { NFT_POST, TOP_NFT_POST } from '@/helpers/elrondApi';
import { Address } from '@multiversx/sdk-core/out';

export interface STAKED_POSITION {
  nonce: number;
  rarity: number;
  staker: string;
  nftNonce: number;
  stakedBlock: number;
  rewardPerShare: any;
  baseStruct: Struct,
}

class TransformHelper {
  public mapMeme(nft: any): NFT_POST {
    const attributes = Buffer.from(nft.attributes, "base64");

    const leftPeriod = attributes.readUInt32BE(0);
    const rightPeriod = attributes.readUInt32BE(4);
    const period = 2 ** 32 * leftPeriod + rightPeriod;

    const length = attributes.readUInt32BE(8);
    const category = attributes.toString(null, 12, 12 + length);
    const creator = Address.fromBuffer(attributes.slice(12 + length)).bech32();

    return {
      name: nft.name,
      uri: nft.url,
      nonce: nft.nonce,
      owner: nft.owner,
      creator,
      period,
      category,
      rarity: 0,
    };
  }

  public mapTopMeme(nft: any): TOP_NFT_POST {
    const attributes = Buffer.from(nft.attributes, "base64");

    const rarity = attributes.readUInt8();

    const leftOriginalNonce = attributes.readUInt32BE(1);
    const rightOriginalNonce = attributes.readUInt32BE(5);
    const originalNonce = 2 ** 32 * leftOriginalNonce + rightOriginalNonce;

    const leftPeriod = attributes.readUInt32BE(9);
    const rightPeriod = attributes.readUInt32BE(13);
    const period = 2 ** 32 * leftPeriod + rightPeriod;

    const length = attributes.readUInt32BE(17);
    const category = attributes.toString(null, 21, 21 + length);
    const creator = Address.fromBuffer(attributes.slice(21 + length)).bech32();

    return {
      name: nft.name,
      uri: nft.url,
      nonce: originalNonce,
      owner: nft.owner,
      creator,
      period,
      category,
      rarity,
      topNonce: nft.nonce,
    };
  }

  public mapStakedPosition(nft: any): STAKED_POSITION {
    const attributes = Buffer.from(nft.attributes, "base64");
    const codec = new BinaryCodec();

    const structType = new StructType('StakingFarmTokenAttributes', [
      new FieldDefinition('rarity', '', new U8Type()),
      new FieldDefinition('staker', '', new AddressType()),
      new FieldDefinition('nft_nonce', '', new U64Type()),
      new FieldDefinition('staked_block', '', new U64Type()),
      new FieldDefinition('reward_per_share', '', new BigUIntType()),
    ]);

    const [decoded] = codec.decodeNested(attributes, structType);
    const stakedPosition = decoded.valueOf();

    return {
      nonce: nft.nonce,
      rarity: stakedPosition.rarity.toNumber(),
      staker: stakedPosition.staker.toString(),
      nftNonce: stakedPosition.nft_nonce.toNumber(),
      stakedBlock: stakedPosition.staked_block.toNumber(),
      rewardPerShare: stakedPosition.reward_per_share.toFixed(),
      baseStruct: decoded as Struct,
    };
  }
}

const transformHelper = new TransformHelper();

export default transformHelper;
