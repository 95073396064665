
import { ROUTES } from '@/router/constants';
import formatterHelper from '@/helpers/formatter';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { USER_ACTIONS, USER_GETTERS } from '@/store/user';
import { VOTE_GETTERS } from '@/store/vote';
import ELROND from '@/constants/elrond';
import BLOCKCHAIN from '@/constants/blockchain';
import { useRoute } from 'vue-router';
import ENV from '@/constants/env';

export default {
  name: 'SidebarAccount',
  created() {
    this.ROUTES = ROUTES;
    this.formatterHelper = formatterHelper;
    this.EXPLORER_URL = ELROND.EXPLORER + '/accounts/';
    this.BLOCKCHAIN = BLOCKCHAIN;
    this.ENV = ENV;
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const tokens = computed(() => {
      const newTokens = { ...store.getters[USER_GETTERS.TOKENS] };

      delete newTokens[ELROND.EGLD_TOKEN];

      return newTokens;
    });

    const getTokenImgUrl = (label) => {
      const tokenInfo = BLOCKCHAIN.TIP_TOKENS?.[label] || BLOCKCHAIN.STAKING_TOKENS?.[label];

      return ELROND.TOKEN_LOGO.replace('{TOKEN}', tokenInfo.logo);
    };

    return {
      address: computed(() => store.getters[USER_GETTERS.ADDRESS_ELROND]),
      accountElrond: computed(() => store.getters[USER_GETTERS.ACCOUNT_ELROND]),
      accountRemainingVotes: computed(() => store.getters[VOTE_GETTERS.USER_REMAINING]),
      userLogoutElrond: () => store.dispatch(USER_ACTIONS.LOGOUT_ELROND),
      tokens,
      getTokenImgUrl,
      route,
    };
  },
};
