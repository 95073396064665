import { TokenPayment } from "@multiversx/sdk-core";
import ELROND from "@/constants/elrond";
import { BigNumber } from "bignumber.js";
import BLOCKCHAIN from '@/constants/blockchain';

const formatterHelper: any = {
  formatAddress: (address: string): string => {
    return address.substr(0, 7) + "..." + address.substr(address.length - 7, 7);
  },
  formatTokenPayment: (tokenPayment: TokenPayment | BigNumber | null, length = 14, token = null): string => {
    if (!tokenPayment) {
      return `0 ${token || "EGLD"}`;
    }

    if (!(tokenPayment instanceof TokenPayment)) {
      if (!token || ELROND.EGLD_TOKEN === token) {
        tokenPayment = TokenPayment.egldFromBigInteger(tokenPayment);
      } else {
        const tokenInfo = BLOCKCHAIN.TIP_TOKENS?.[token] || BLOCKCHAIN.STAKING_TOKENS?.[token];

        tokenPayment = TokenPayment.fungibleFromBigInteger(token, tokenPayment, tokenInfo?.decimals);
        token = tokenInfo?.name;
      }
    }

    let str = tokenPayment.toRationalNumber();

    if (length) {
      length = Math.max(length, str.indexOf('.') + 1);
    }

    if (length && str.length > length) {
      str = str.substring(0, length);
    }

    let index = str.length - 1;
    while ('0' === str[index]) {
      index--;
    }

    str = str.substring(0, index + 1);

    if ('.' === str[str.length - 1]) {
      str = str.substring(0, str.length - 1);
    }

    if (null === token) {
      const tokenInfo = BLOCKCHAIN.TIP_TOKENS?.[tokenPayment.tokenIdentifier] || BLOCKCHAIN.STAKING_TOKENS?.[tokenPayment.tokenIdentifier];

      token = tokenInfo?.name;
    }

    str = new Intl.NumberFormat(undefined, { maximumFractionDigits: 6, minimumSignificantDigits: 3 }).format(Number.parseFloat(str));

    return `${str} ${token || "EGLD"}`;
  },
  formatEgld: (egld: number): string => {
    return egld / ELROND.EGLD_DENOMINATOR + " EGLD";
  },
  formatDateTime(date) {
    if (!date) {
      return "";
    }

    // @ts-ignore
    return new Intl.DateTimeFormat(undefined, { dateStyle: "short", timeStyle: "short" }).format(new Date(date * 1000));
  },
  formatDate(date) {
    if (!date) {
      return "";
    }

    // @ts-ignore
    return new Intl.DateTimeFormat(undefined, { dateStyle: "short" }).format(new Date(date * 1000));
  },
  formatPeriod(date, secondDate = null) {
    const first = formatterHelper.formatDate(date);
    const second = formatterHelper.formatDate(secondDate || (date + BLOCKCHAIN.PERIOD_TIME));

    return `${first} - ${second}`;
  },
  getHoursRemaining(date) {
    const now = Date.now();
    const difference = date * 1000 - now;

    if (0 > difference) {
      return 0;
    }

    return Math.round(difference / 3.6e6);
  },
  formatBlockDifference(currentBlock: number, endBlock: number) {
    const value = currentBlock > endBlock ? 0 : (endBlock - currentBlock) * ELROND.TIME_PER_BLOCK;

    // @ts-ignore
    return new Intl.RelativeTimeFormat(undefined).format(value / (60 * 60 * 24), 'days');
  },
};

export default formatterHelper;
