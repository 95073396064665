
import { useStore } from 'vuex';
import { computed } from 'vue';
import { USER_GETTERS } from '@/store/user';
import { ROUTES } from '@/router/constants';
import ENV from '@/constants/env';
import SidebarAccount from '@/components/base/SidebarAccount.vue';
import ELROND from '@/constants/elrond';
import { AUCTION_GETTERS } from '@/store/auction';
import { useRoute } from 'vue-router';
import SidebarExtra from '@/components/base/SidebarExtra.vue';

export default {
  name: 'SidebarMobile',
  components: { SidebarExtra, SidebarAccount },
  created() {
    this.ROUTES = ROUTES;
    this.ENV = ENV;
    this.ELROND = ELROND;
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    return {
      address: computed(() => store.getters[USER_GETTERS.ADDRESS_ELROND]),
      specialAuctionPeriod: computed(() => store.getters[AUCTION_GETTERS.UNENDED_CUSTOM_PERIOD]),
      route,
    };
  },
};
