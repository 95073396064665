import api, { API_PATHS } from "@/api/index";

class AuctionClient {
  async customPeriods(): Promise<number[]> {
    const response = await api.get(API_PATHS.AUCTION_CUSTOM_PERIODS);

    return response.data.map(period => Number.parseInt(period));
  }
}

const auctionClient = new AuctionClient();

export default auctionClient;
