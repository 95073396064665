import {
  Account,
  Address,
  BytesValue,
  ContractFunction,
  List,
  Struct,
  TokenPayment,
  Transaction,
  TransactionPayload,
  U32Value,
  U64Value,
  VariadicValue,
} from "@multiversx/sdk-core";
import { BigNumber } from "bignumber.js";
import elrondHelper from "@/helpers/elrond";
import BLOCKCHAIN from "@/constants/blockchain";
import BaseContract from '@/contracts/baseContract';

export interface MEME_VOTES {
  nonce: number;
  votes: number;
}

export const MEME_VOTING_CONSTANTS = {
  ERRORS: {
    THROTTLE: "Address already created a meme in the last 10 minutes",
    VOTES: "Not enough votes left currently",
    NOT_EXIST: "Meme does not exist",
  },
  THROTTLE_MEME_TIME: 600,
  VOTES_PER_ADDRESS_PER_PERIOD: 10,
  MAX_REFERED_MAX_VOTES_THRESHLD: 45,

  EXTRA_VOTES_IF_REFERRED: 15,
};

export const MEME_VOTING_FUNCTIONS = {
  CREATE_MEME: "create_meme",
  VOTE_MEMES: "vote_memes",
};

class MemeVotingContract extends BaseContract {
  constructor() {
    super(BLOCKCHAIN.MEMES_VOTING_CONTRACT, 'memes-voting', 'MemesVoting')
  }

  // Endpoints
  async createMeme(
    account: Account,
    name: string,
    url: string,
    category: string,
    signature: string
  ): Promise<Transaction> {
    const func = new ContractFunction(MEME_VOTING_FUNCTIONS.CREATE_MEME);
    const payload = TransactionPayload.contractCall()
      .setFunction(func)
      .setArgs([
        BytesValue.fromUTF8(name),
        BytesValue.fromUTF8(url),
        BytesValue.fromUTF8(category),
        BytesValue.fromHex(signature),
      ])
      .build();

    // const gasValue = networkConfig.MinGasLimit.valueOf() + this.data.length() * networkConfig.GasPerDataByte.valueOf();

    const transaction = new Transaction({
      sender: account.address,
      receiver: new Address(BLOCKCHAIN.MEMES_VOTING_CONTRACT),
      value: TokenPayment.egldFromAmount(0),
      gasLimit: 50000000, // contains 25M gas from meme voting, 20M gas from meme auction + 5M just in case
      data: payload,
      nonce: account.nonce,
      chainID: elrondHelper.networkConfig.ChainID,
    });

    return await elrondHelper.sendTransaction(transaction, account);
  }

  async voteMemes(account: Account, signature: string, nonces: number[]): Promise<Transaction> {
    const func = new ContractFunction(MEME_VOTING_FUNCTIONS.VOTE_MEMES);
    const payload = TransactionPayload.contractCall()
      .setFunction(func)
      .setArgs([BytesValue.fromHex(signature), ...nonces.map((nonce) => new U64Value(new BigNumber(nonce)))])
      .build();

    const transaction = new Transaction({
      sender: account.address,
      receiver: new Address(BLOCKCHAIN.MEMES_VOTING_CONTRACT),
      value: TokenPayment.egldFromAmount(0),
      gasLimit: 20000000, // 15M base, + 5M just in case
      data: payload,
      nonce: account.nonce,
      chainID: elrondHelper.networkConfig.ChainID,
    });

    return await elrondHelper.sendTransaction(transaction, account);
  }

  // Views
  async currentPeriodLen(): Promise<number> {
    const query = await this.contract.createQuery({
      func: new ContractFunction("current_period_len"),
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = (await this.getContractAbi()).getEndpoint("current_period_len");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return firstValue.valueOf().toNumber();
  }

  async currentPeriodMemesLatest(page: number): Promise<MEME_VOTES[]> {
    const query = await this.contract.createQuery({
      func: new ContractFunction("current_period_memes_latest"),
      args: [new U32Value(page)],
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = (await this.getContractAbi()).getEndpoint("current_period_memes_latest");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return (firstValue as VariadicValue).getItems().map((result: Struct) => ({
      nonce: result.getFieldValue("field0").toNumber(),
      votes: result.getFieldValue("field1").toNumber(),
    }));
  }

  async periodLen(period: number): Promise<number> {
    const query = await this.contract.createQuery({
      func: new ContractFunction("period_len"),
      args: [new U64Value(new BigNumber(period))],
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = (await this.getContractAbi()).getEndpoint("period_len");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return firstValue.valueOf().toNumber();
  }

  async periodMemesLatest(period: number, page: number): Promise<MEME_VOTES[]> {
    const query = await this.contract.createQuery({
      func: new ContractFunction("period_memes_latest"),
      args: [new U64Value(new BigNumber(period)), new U32Value(page)],
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = (await this.getContractAbi()).getEndpoint("period_memes_latest");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return (firstValue as VariadicValue).getItems().map((result: Struct) => ({
      nonce: result.getFieldValue("field0").toNumber(),
      votes: result.getFieldValue("field1").toNumber(),
    }));
  }

  async periodTopMemes(period: number): Promise<MEME_VOTES[]> {
    const query = await this.contract.createQuery({
      func: new ContractFunction("period_top_memes"),
      args: [new U64Value(new BigNumber(period))],
    });

    const result = await elrondHelper.cachedProxy.queryContract(query);

    const endpointDefinition = (await this.getContractAbi()).getEndpoint("period_top_memes");
    const { firstValue } = this.resultParser.parseQueryResponse(result, endpointDefinition);

    return (firstValue as List).getItems().map((result: Struct) => ({
      nonce: result.getFieldValue("nft_nonce").toNumber(),
      votes: result.getFieldValue("votes").toNumber(),
    }));
  }

  // Helpers
  calculateReferalVotesModifier(nbReferals: number): number {
    if (MEME_VOTING_CONSTANTS.MAX_REFERED_MAX_VOTES_THRESHLD < nbReferals) {
      return MEME_VOTING_CONSTANTS.MAX_REFERED_MAX_VOTES_THRESHLD;
    }

    return nbReferals;
  }
}

const memeVotingContract = new MemeVotingContract();

export default memeVotingContract;
