import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mx-auto text-center" }
const _hoisted_3 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('pages.notFound.title')), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('pages.notFound.subtitle')), 1),
      _createElementVNode("h4", null, [
        _createVNode(_component_router_link, {
          to: { name: _ctx.ROUTES.home }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('pages.notFound.link')), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ])
  ]))
}