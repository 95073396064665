import Home from "@/pages/Home.vue"
import { ROUTES } from "@/router/constants";
import store from '@/store';
import { USER_GETTERS } from '@/store/user';

const routerHelper = {
  modalRoute(to, from, next) {
    if (from.matched.length && (ROUTES.login !== to.name || !to.query?.redirect)) {
      // Add child routes of previous routes
      const childrenView = from.matched.slice(1, from.matched.length)
      for (const view of childrenView) {
        to.matched.push(view)
      }
      // Set the default component to the default of the previous one.
      to.matched[0].components.default = from.matched[0].components.default;
    } else {
      if (!to.matched[0].components?.default) {
        to.matched[0].components.default = Home;
      }
    }

    next()
  },
  isAnonymous(to, from, next, modal = false) {
    if (store.getters[USER_GETTERS.ADDRESS_ELROND]) {
      next({ name: ROUTES.home })

      return;
    }

    if (modal) {
      this.modalRoute(to, from, next);
    } else {
      next();
    }
  },
  getUserState () {
    return new Promise((resolve, reject) => {
      if (false === store.getters[USER_GETTERS.ADDRESS_ELROND]) {
        const unwatch = store.watch(
          () => store.getters[USER_GETTERS.ADDRESS_ELROND],
          (value) => {
            unwatch()
            resolve(value)
          }
        )
      } else {
        resolve(store.getters[USER_GETTERS.ADDRESS_ELROND])
      }
    })
  },
  async isLoggedIn(to, from, next, modal = false) {
    const user = await routerHelper.getUserState();

    if (!user) {
      next({ name: ROUTES.login, query: { redirect: to.fullPath } })

      return;
    }

    if (modal) {
      this.modalRoute(to, from, next);
    } else {
      next();
    }
  },
  beforeModalRoute(to, from, next) {
    next(c => {
      if (from && from.name) {
        c.prevRoute = from;
      }
    });
  }
};

export default routerHelper;
