import api, { API_PATHS } from '@/api/index';

class AuthClient {
  async authToken(): Promise<string> {
    const response = await api.post(API_PATHS.AUTH_TOKEN);

    return response.data;
  }

  async authLogin(address: string, token: string, signature: string): Promise<string> {
    const response = await api.post(API_PATHS.AUTH_LOGIN, {
      address,
      token,
      signature,
    });

    return response.data;
  }
}

const authClient = new AuthClient();

export default authClient;
