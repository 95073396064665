import { createApp } from 'vue'
import vSelect from "vue-select";

import App from './App.vue'
import store from "./store"
import i18n from '@/plugins/i18n';
import router from '@/router';

import 'bootstrap';

import 'bootstrap-icons/font/bootstrap-icons.css';
import "vue-select/dist/vue-select.css";

import './assets/css/app.scss';

const app = createApp(App);

app.use(store)
app.use(i18n);
app.use(router);

app.component('v-select', vSelect);

app.mount('#app');
