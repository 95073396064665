import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-3 p-md-5 text-center shadow-lg mb-5 background-banner" }
const _hoisted_2 = { class: "mx-auto my-3 animated-info" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "mx-auto my-3 animated-info" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "mx-auto my-3 animated-info" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "mb-2 fs-5 font-baskerville" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "fs-5 font-baskerville" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_14 = { class: "text-center" }
const _hoisted_15 = { class: "mb-0" }
const _hoisted_16 = { class: "fw-normal fs-5" }
const _hoisted_17 = { class: "text-secondary" }
const _hoisted_18 = { class: "col-12 col-md-8 col-lg-4 mx-auto" }
const _hoisted_19 = {
  key: 1,
  class: "row"
}
const _hoisted_20 = { class: "d-grid col-12 col-md-6 col-lg-4 mx-auto my-3" }
const _hoisted_21 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HelpAccordion = _resolveComponent("HelpAccordion")!
  const _component_PageLoader = _resolveComponent("PageLoader")!
  const _component_ListNone = _resolveComponent("ListNone")!
  const _component_MemePost = _resolveComponent("MemePost")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", {
          innerHTML: _ctx.$t('pages.home.memes'),
          class: "font-karnivore-black text-white"
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h2", {
          innerHTML: _ctx.$t('pages.home.nfts'),
          class: "font-karnivore-black text-white"
        }, null, 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("h2", {
          innerHTML: _ctx.$t('pages.home.headline'),
          class: "font-karnivore-black mb-3 text-white"
        }, null, 8, _hoisted_7),
        _createVNode(_component_HelpAccordion, null, {
          default: _withCtx(() => [
            _createElementVNode("p", {
              innerHTML: _ctx.$t('pages.home.create'),
              class: "my-2 mt-3 fs-5 font-baskerville"
            }, null, 8, _hoisted_8),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('pages.home.vote')), 1),
            _createElementVNode("p", {
              innerHTML: _ctx.$t('pages.home.auction'),
              class: "mb-2 fs-5 font-baskerville"
            }, null, 8, _hoisted_10),
            _createElementVNode("em", _hoisted_11, [
              _createElementVNode("small", {
                innerHTML: _ctx.$t('pages.home.docs')
              }, null, 8, _hoisted_12)
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_PageLoader, { loading: $setup.loading }, null, 8, ["loading"]),
    ($setup.memes)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("h5", _hoisted_15, _toDisplayString(_ctx.$t('meme.list.period')) + ":", 1),
            _createElementVNode("em", _hoisted_16, _toDisplayString(_ctx.formatterHelper.formatPeriod($setup.currentPeriod)), 1),
            _createElementVNode("h5", _hoisted_17, _toDisplayString(_ctx.$t('meme.list.total')) + ": " + _toDisplayString($setup.memes.total), 1),
            (!$setup.memes.total)
              ? (_openBlock(), _createBlock(_component_ListNone, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('meme.list.none')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.memes.items, (meme) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row",
              key: meme.nonce
            }, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_MemePost, {
                  meme: meme,
                  "can-vote": true
                }, null, 8, ["meme"])
              ])
            ]))
          }), 128)),
          ($setup.pageWithOlderMemes === -1)
            ? (_openBlock(), _createBlock(_component_Pagination, {
                key: 0,
                loading: $setup.loading,
                list: $setup.memes,
                "load-more": $setup.loadMore
              }, null, 8, ["loading", "list", "load-more"]))
            : _createCommentVNode("", true),
          (($setup.memes.items.length >= $setup.memes.total || $setup.pageWithOlderMemes > -1) && !$setup.isLastPageWithOlderMemes)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("button", {
                    class: "btn btn-outline-secondary mb-5 mb-md-0",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.loadOlderMemes && $setup.loadOlderMemes(...args))),
                    disabled: $setup.loading
                  }, [
                    _createVNode(_component_Loader, { loading: $setup.loading }, null, 8, ["loading"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('meme.list.loadMore')), 1)
                  ], 8, _hoisted_21)
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}