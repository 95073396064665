
import { useStore } from "vuex"
import { computed, onMounted, ref, watch } from "vue"
import { VOTE_ACTIONS, VOTE_GETTERS, VOTE_MUTATIONS } from "@/store/vote"
import elrondHelper from "@/helpers/elrond"
import { USER_GETTERS } from "@/store/user"
import { BASE_MUTATIONS } from "@/store"
import { MEME_VOTING_CONSTANTS, MEME_VOTING_FUNCTIONS } from '@/contracts/memeVoting';
import { MEME_MUTATIONS } from '@/store/meme';
import Loader from '@/components/helpers/Loader.vue';
import { Account, Transaction } from "@multiversx/sdk-core";
import BLOCKCHAIN from '@/constants/blockchain';
import ENV from "@/constants/env";
import memeClient from "@/api/meme";

export default {
  name: "VotesSubmit",
  components: { Loader },
  setup() {
    const store = useStore();
    const address = computed(() => store.getters[USER_GETTERS.ADDRESS_ELROND]);
    const tempVotes = computed(() => store.getters[VOTE_GETTERS.TEMP]);

    const loading = ref(false);

    const accountElrond = computed<Account | null>(() => store.getters[USER_GETTERS.ACCOUNT_ELROND]);

    const voteSubmitCaptcha = async (captchaResult) => {
      loading.value = true;

      const response = await memeClient.memeVote(tempVotes.value, captchaResult);

      if (!response) {
        loading.value = false;

        store.commit(BASE_MUTATIONS.MESSAGE_MODAL, {
          variant: 'danger',
          message: 'meme.vote.errorCaptcha',
        });

        return;
      }

      const transaction = await store.dispatch(VOTE_ACTIONS.SUBMIT, { signature: response.signature, accountElrond: accountElrond.value });

      if (transaction) {
        const pendingTransactionStatus = await elrondHelper.getPendingTransaction(
          address.value,
          BLOCKCHAIN.MEMES_VOTING_CONTRACT,
          MEME_VOTING_FUNCTIONS.VOTE_MEMES,
          transaction
        );

        if (null !== pendingTransactionStatus) {
          // @ts-ignore
          if (true === pendingTransactionStatus) {
            await store.commit(VOTE_MUTATIONS.USER_REMAINING_REMOVE);
            await store.commit(MEME_MUTATIONS.ADD_VOTES, tempVotes.value);
            voteDiscard();

            store.commit(BASE_MUTATIONS.MESSAGE_MODAL, {
              variant: 'success',
              message: 'meme.vote.success',
            });
          }
          // @ts-ignore
          else if (pendingTransactionStatus === MEME_VOTING_CONSTANTS.ERRORS.VOTES) {
            voteDiscard();

            store.commit(BASE_MUTATIONS.MESSAGE_MODAL, {
              variant: 'danger',
              message: 'meme.vote.error',
            });
          } else {
            store.commit(BASE_MUTATIONS.MESSAGE_MODAL, {
              variant: 'danger',
              message: 'messages.error.transaction',
            });
          }
        }
      }

      loading.value = false;
    };

    const voteSubmit = async () => {
      captcha.value.showBox();
    }

    const voteDiscard = () => {
      store.commit(VOTE_MUTATIONS.TEMP, []);
    }

    const captcha = ref(null);

    watch(tempVotes, (newValue, oldValue) => {
      if (0 === oldValue.length && newValue.length > 0) {
        // @ts-ignore
        window.initGeetest4({
          captchaId: ENV.CAPTCHA_ID,
          product: 'bind',
        }, (c) => {
          captcha.value = c;

          c.appendTo('#voteCaptcha').onSuccess(() => {
            const result = c.getValidate();

            voteSubmitCaptcha(result);
          });
        })
      }
    });

    return {
      tempVotes,
      loading,
      voteSubmit,
      voteDiscard
    }
  }
}
