import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal fade",
  style: {"z-index":"3000"},
  id: "modal-message",
  tabindex: "-1",
  "aria-labelledby": "modalMessage",
  "aria-hidden": "true",
  ref: "messageModalRef"
}
const _hoisted_2 = { class: "modal-dialog" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = {
  key: 0,
  class: "modal-title",
  id: "modalMessage"
}
const _hoisted_6 = { class: "modal-body pb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.messageModal)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              ($setup.messageModal && $setup.messageModal.title && _ctx.$te($setup.messageModal.title))
                ? (_openBlock(), _createElementBlock("h5", _hoisted_5, _toDisplayString(_ctx.$t($setup.messageModal.title)), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: _normalizeClass('alert alert-' + $setup.messageModal.variant)
              }, _toDisplayString(_ctx.$t($setup.messageModal.message, $setup.messageModal.messageParams || {})), 3)
            ])
          ])
        ])
      ], 512))
    : _createCommentVNode("", true)
}