
import { PropType } from 'vue';
import Loader from '@/components/helpers/Loader.vue';
import { LIST } from '@/helpers/store';

export default {
  name: "Pagination",
  components: { Loader },
  props: {
    loading: Boolean,
    list: Object as PropType<LIST<any>>,
    loadMore: Function as PropType<() => void>,
  }
}
