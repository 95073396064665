
import { Modal } from "bootstrap";
import { useStore } from "vuex"
import { computed, ref, watch } from "vue"
import { BASE_GETTERS, BASE_MUTATIONS } from '@/store';

export default {
  name: 'ConfirmModal',
  setup() {
    const store = useStore();

    const modal = ref(null);
    const confirmModalRef = ref(null);
    const confirmModal = computed(() => store.getters[BASE_GETTERS.CONFIRM_MODAL]);
    const hideModal = () => {
      const onHide = confirmModal.value && confirmModal.value.onHide;

      store.commit(BASE_MUTATIONS.CLOSE_CONFIRM_MODAL);

      if (onHide) {
        onHide();
      }
    };

    watch(confirmModalRef, () => {
      if (!confirmModal.value || !confirmModalRef.value) {
        return;
      }

      modal.value = new Modal(confirmModalRef.value);
      modal.value.show();
      confirmModalRef.value.addEventListener('hidden.bs.modal', hideModal);
    });

    const confirm = () => {
      modal.value.hide();

      const onConfirm = confirmModal.value && confirmModal.value.onConfirm;
      if (onConfirm) {
        onConfirm();
      }
    }

    return {
      modal,
      confirmModal,
      confirmModalRef,
      confirm,
    }
  },
}
