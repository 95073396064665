import ENV from '@/constants/env';

export interface ITOKEN {
  name: string;
  logo: string;
  decimals: number;
}

const TIP_TOKENS: { [key: string]: ITOKEN } = {};
const STAKING_TOKENS: { [key: string]: ITOKEN } = {};

ENV.TIP_TOKENS.split(";").forEach(value => {
  const split = value.split(":");
  const tokenId = split[0];

  TIP_TOKENS[tokenId] = {
    name: tokenId.split("-")[0],
    logo: split[1],
    decimals: Number.parseInt(split[2]),
  };
});

ENV.STAKING_TOKENS.split(";").forEach(value => {
  const split = value.split(":");
  const tokenId = split[0];

  STAKING_TOKENS[tokenId] = {
    name: tokenId.split("-")[0],
    logo: split[1],
    decimals: Number.parseInt(split[2]),
  };
});

const BLOCKCHAIN = {
  NFT_TOKEN_IDENTIFIER: ENV.NFT_TOKEN_IDENTIFIER,
  MEMES_VOTING_CONTRACT: ENV.CONTRACT_MEME_VOTING,

  TOP_NFT_TOKEN_IDENTIFIER: ENV.TOP_NFT_TOKEN_IDENTIFIER,
  MEMES_AUCTION_CONTRACT: ENV.CONTRACT_MEME_AUCTION,

  PERIOD_TIME: ENV.PERIOD_TIME,

  META_STAKING_TOKEN_IDENTIFIER: ENV.META_STAKING_TOKEN_IDENTIFIER,
  MEMES_STAKING_CONTRACT: ENV.CONTRACT_MEME_STAKING,

  TIP_TOKENS,
  STAKING_TOKENS,

  CATEGORIES: ['funny'],
  // TODO: Remove this when categories are properly implemented
  DEFAULT_CATEGORY: 'funny',
};

export default BLOCKCHAIN;
