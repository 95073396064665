import ENV from "@/constants/env";

const ELROND_PER_ENV = {
  DEVNET: {
    GATEWAY: "https://devnet-gateway.multiversx.com",
    API: "https://devnet-api.multiversx.com",
    EXPLORER: "https://devnet-explorer.multiversx.com",
  },

  TESTNET: {
    GATEWAY: "https://testnet-gateway.multiversx.com",
    API: "https://testnet-api.multiversx.com",
    EXPLORER: "https://testnet-explorer.multiversx.com",
  },

  MAINNET: {
    GATEWAY: "https://gateway.multiversx.com",
    API: "https://api.multiversx.com", // TODO: Use BlastApi endpoint?
    EXPLORER: "https://explorer.multiversx.com",
  },
};

const ELROND = {
  WALLET_CONNECT_WS: 'wss://relay.walletconnect.com',
  WALLET_CONNECT_PROJECT_ID: '9d5555c673afd0766a5e96b20f1c7fa8',
  WALLET_CONNECT_DEEP_LINK: "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/",
  EGLD_TOKEN: 'EGLD',
  EGLD_DENOMINATOR: 1000000000000000000, // 1 EGLD
  EMPTY_ADDRESS: "erd1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq6gq4hu",

  BLOCKS_PER_DAY: 14400,
  TIME_PER_BLOCK: 6, // 6 seconds per block

  TOKEN_LOGO: `https://media.elrond.com/tokens/asset/{TOKEN}/logo.svg`,

  ...ELROND_PER_ENV[ENV.ELROND_NET],
};

export default ELROND;
