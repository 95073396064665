
import { Modal } from "bootstrap";
import { useStore } from "vuex"
import { BASE_GETTERS, BASE_MUTATIONS } from "../../store"
import { computed, ref, watch } from "vue"

export default {
  name: 'MessageModal',
  setup() {
    const store = useStore();

    const modal = ref(null);
    const messageModalRef = ref(null);
    const messageModal = computed(() => store.getters[BASE_GETTERS.MESSAGE_MODAL]);
    const hideModal = () => {
      const onHide = messageModal.value && messageModal.value.onHide;

      store.commit(BASE_MUTATIONS.CLOSE_MESSAGE_MODAL);

      if (onHide) {
        onHide();
      }
    }

    watch(messageModalRef, () => {
      if (!messageModal.value || !messageModalRef.value) {
        return;
      }

      modal.value = new Modal(messageModalRef.value);
      modal.value.show();
      messageModalRef.value.addEventListener('hidden.bs.modal', hideModal);
    });

    return {
      messageModal,
      messageModalRef,
    }
  },
}
