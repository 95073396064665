import Home from "@/pages/Home.vue";
import NotFound from '@/pages/NotFound.vue';
import { PATHS_EN, ROUTES } from "@/router/constants";
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import routerHelper from '@/helpers/router';

const routerRoutes: Array<RouteRecordRaw> = [
  {
    path: PATHS_EN[ROUTES.home],
    name: ROUTES.home,
    component: Home,
  },

  // Security
  {
    path: PATHS_EN[ROUTES.login],
    name: ROUTES.login,
    components: {
      modal: () => import(/* webpackChunkName: "login" */ '../pages/security/Login.vue')
    },
    beforeEnter: (to, from, next) => routerHelper.isAnonymous(to, from, next, true),
  },

  // User
  {
    path: PATHS_EN[ROUTES.user.memesCreated],
    name: ROUTES.user.memesCreated,
    component: () => import('../pages/user/MemesCreated.vue'),
  },
  {
    path: PATHS_EN[ROUTES.user.memesWallet],
    name: ROUTES.user.memesWallet,
    component: () => import('../pages/user/MemesWallet.vue'),
  },
  {
    path: PATHS_EN[ROUTES.user.topMemesWallet],
    name: ROUTES.user.topMemesWallet,
    component: () => import('../pages/user/TopMemesWallet.vue'),
  },

  // Meme
  {
    path: PATHS_EN[ROUTES.meme.create],
    name: ROUTES.meme.create,
    components: {
      modal: () => import('../pages/meme/Create.vue')
    },
    beforeEnter: (to, from, next) => routerHelper.isLoggedIn(to, from, next, true),
  },
  {
    path: PATHS_EN[ROUTES.meme.view],
    name: ROUTES.meme.view,
    components: {
      modal: () => import('../pages/meme/View.vue')
    },
    beforeEnter: routerHelper.modalRoute,
  },
  {
    path: PATHS_EN[ROUTES.meme.listTop],
    name: ROUTES.meme.listTop,
    component: () => import('../pages/meme/ListTop.vue'),
  },
  {
    path: PATHS_EN[ROUTES.meme.listTopOlder],
    name: ROUTES.meme.listTopOlder,
    component: () => import('../pages/meme/ListTopOlder.vue'),
  },
  {
    path: PATHS_EN[ROUTES.meme.listOlder],
    name: ROUTES.meme.listOlder,
    component: () => import('../pages/meme/ListOlder.vue'),
  },

  // Auction
  {
    path: PATHS_EN[ROUTES.auction.view],
    name: ROUTES.auction.view,
    components: {
      default: () => import('../pages/auction/ListCurrent.vue'),
      modal: () => import('../pages/auction/View.vue')
    },
    beforeEnter: routerHelper.modalRoute,
  },
  {
    path: PATHS_EN[ROUTES.auction.listCurrent],
    name: ROUTES.auction.listCurrent,
    component: () => import('../pages/auction/ListCurrent.vue'),
  },
  {
    path: PATHS_EN[ROUTES.auction.special],
    name: ROUTES.auction.special,
    component: () => import('../pages/auction/ListCurrent.vue'),
  },
  {
    path: PATHS_EN[ROUTES.auction.listOlder],
    name: ROUTES.auction.listOlder,
    component: () => import('../pages/auction/ListOlder.vue'),
  },

  // Staking
  {
    path: PATHS_EN[ROUTES.staking],
    name: ROUTES.staking,
    component: () => import('../pages/staking/Staking.vue'),
  },

  // Referrals
  {
    path: PATHS_EN[ROUTES.referrals],
    name: ROUTES.referrals,
    component: () => import('../pages/referrals/Referrals.vue'),
    beforeEnter: (to, from, next) => routerHelper.isLoggedIn(to, from, next),
  },

  // 404 Not Found page
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
  }
];

export default createRouter({
  history: createWebHistory(),
  routes: routerRoutes
});
