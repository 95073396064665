import { AbiRegistry, Address, ResultsParser, SmartContract, SmartContractAbi, } from "@multiversx/sdk-core";
import axios from "axios";

abstract class BaseContract {
  public contract: SmartContract;
  public abi: SmartContractAbi;
  public resultParser: ResultsParser;

  abiJson: string;
  abiInterface: string;

  constructor(address: string, abiJson: string, abiInterface: string) {
    this.contract = new SmartContract({
      address: new Address(address),
    });
    this.resultParser = new ResultsParser();

    this.abiJson = abiJson;
    this.abiInterface = abiInterface;
  }

  public async getContractAbi(): Promise<SmartContractAbi> {
    if (!this.abi) {
      const response = await axios.get(`/abi/${this.abiJson}.abi.json`);
      const abiRegistry = AbiRegistry.create(response.data);

      this.abi = new SmartContractAbi(abiRegistry, [this.abiInterface]);
      this.contract = new SmartContract({
        address: this.contract.getAddress(),
        abi: this.abi,
      });
    }

    return this.abi;
  }
}

export default BaseContract;
