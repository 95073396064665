
import { ROUTES } from '@/router/constants';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { USER_GETTERS } from '@/store/user';
import { useStore } from 'vuex';

export default {
  name: 'SidebarExtra',
  created() {
    this.ROUTES = ROUTES;
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    return {
      route,
      address: computed(() => store.getters[USER_GETTERS.ADDRESS_ELROND]),
    };
  },
};
