import BLOCKCHAIN from "@/constants/blockchain";
import ELROND from "@/constants/elrond";
import GENERAL from "@/constants/general";
import { ApiNetworkProvider, FungibleTokenOfAccountOnNetwork } from "@multiversx/sdk-network-providers";
import { Account } from "@multiversx/sdk-core";
import transformHelper, { STAKED_POSITION } from '@/helpers/transformHelper';
import { Address } from '@multiversx/sdk-core/out';

export interface NFT_POST {
  name: string;
  uri: string;
  nonce: number;
  owner: string;
  creator: string;
  period: number;
  category: string;
  rarity: number;
}

export interface TOP_NFT_POST extends NFT_POST {
  topNonce: number;
}

class ElrondApiHelper {
  apiProvider: ApiNetworkProvider;

  constructor() {
    this.apiProvider = new ApiNetworkProvider(ELROND.API, { timeout: 10000 });
  }

  async getAddressNftsCount(address, token: string = BLOCKCHAIN.NFT_TOKEN_IDENTIFIER): Promise<number> {
    return await this.apiProvider.doGetGeneric(`accounts/${address}/nfts/count?collection=${token}`);
  }

  async getAddressNfts(
    address: string,
    page: number,
    count: number,
    token: string = BLOCKCHAIN.NFT_TOKEN_IDENTIFIER
  ): Promise<NFT_POST[]> {
    if (count <= page * GENERAL.PER_PAGE) {
      return [];
    }

    // Reverse nfts call since for address nfts are ordered by default in asc not desc order
    const lastIndex = count - page * GENERAL.PER_PAGE;
    const firstIndex = lastIndex > GENERAL.PER_PAGE ? lastIndex - GENERAL.PER_PAGE : 0;
    const size = Math.min(lastIndex - firstIndex, GENERAL.PER_PAGE);

    const response = await this.apiProvider.doGetGeneric(
      `accounts/${address}/nfts?collection=${token}&from=${firstIndex}&size=${size}`
    );

    return response.reverse().map((nft) =>
      BLOCKCHAIN.TOP_NFT_TOKEN_IDENTIFIER === token
        ? transformHelper.mapTopMeme({
            ...nft,
            owner: address,
          })
        : transformHelper.mapMeme({ ...nft, owner: address })
    );
  }

  async getAddressStakedPositions(
    address: string,
    page: number,
    count: number
  ): Promise<STAKED_POSITION[]> {
    if (count <= page * GENERAL.PER_PAGE) {
      return [];
    }

    // Reverse nfts call since for address nfts are ordered by default in asc not desc order
    const lastIndex = count - page * GENERAL.PER_PAGE;
    const firstIndex = lastIndex > GENERAL.PER_PAGE ? lastIndex - GENERAL.PER_PAGE : 0;
    const size = Math.min(lastIndex - firstIndex, GENERAL.PER_PAGE);

    const response = (await this.apiProvider.doGetGeneric(
      `accounts/${address}/nfts?collection=${BLOCKCHAIN.META_STAKING_TOKEN_IDENTIFIER}&from=${firstIndex}&size=${size}`
    )).reverse();

    return response.map((nft) => transformHelper.mapStakedPosition(nft));
  }

  async getCreatedNftsCount(creator): Promise<number> {
    return await this.apiProvider.doGetGeneric(
      `nfts/count?collection=${BLOCKCHAIN.NFT_TOKEN_IDENTIFIER}&creator=${creator}`
    );
  }

  async getCreatedNfts(creator: string, page: number, count: number): Promise<NFT_POST[]> {
    if (count <= page * GENERAL.PER_PAGE) {
      return [];
    }

    const firstIndex = page * GENERAL.PER_PAGE;
    const size = GENERAL.PER_PAGE;

    const response = await this.apiProvider.doGetGeneric(
      `nfts?collection=${BLOCKCHAIN.NFT_TOKEN_IDENTIFIER}&creator=${creator}&from=${firstIndex}&size=${size}&withOwner=true`
    );

    return response.map((nft) => transformHelper.mapMeme(nft));
  }

  async getNfts(nonces: number[], token: string = BLOCKCHAIN.NFT_TOKEN_IDENTIFIER): Promise<{ [nonce: number]: NFT_POST | TOP_NFT_POST }> {
    if (!nonces.length) {
      return {};
    }

    const newNonces = nonces
      .map((nonce) => {
        let newNonce: string = nonce.toString(16);

        if (newNonce.length < 2) {
          newNonce = "0" + newNonce;
        }

        return `${token}-${newNonce}`;
      })
      .join(",");

    const response = await this.apiProvider.doGetGeneric(`nfts?identifiers=${ newNonces }&withOwner=true`);

    return response.reduce((acc, nft) => {
      if (BLOCKCHAIN.TOP_NFT_TOKEN_IDENTIFIER === token) {
        acc[nft.nonce] = transformHelper.mapTopMeme(nft);
      } else {
        acc[nft.nonce] = transformHelper.mapMeme(nft);
      }

      return acc;
    }, {});
  }

  async getNft(nonce: string | number, token: string = BLOCKCHAIN.NFT_TOKEN_IDENTIFIER): Promise<NFT_POST | TOP_NFT_POST> {
    if ("number" === typeof nonce) {
      nonce = nonce.toString(16);
    }

    if (nonce.length < 2) {
      nonce = "0" + nonce;
    }

    const response = await this.apiProvider.doGetGeneric(`nfts/${token}-${nonce}`);

    if (BLOCKCHAIN.TOP_NFT_TOKEN_IDENTIFIER === token) {
      return transformHelper.mapTopMeme(response);
    }

    return transformHelper.mapMeme(response);
  }

  async getAccount(addressStr: string): Promise<Account> {
    const address = new Address(addressStr);
    const account = new Account(address);

    const accountOnNetwork = await this.apiProvider.getAccount(address);

    account.update(accountOnNetwork);

    return account;
  }

  async getAccountTokenBalance(address: string, token: string): Promise<FungibleTokenOfAccountOnNetwork> {
    return await this.apiProvider.getFungibleTokenOfAccount(new Address(address), token);
  }
}

const elrondApiHelper = new ElrondApiHelper();

export default elrondApiHelper;
