import api, { API_PATHS } from "@/api/index";

class MemeClient {
  async memeCreate(
    file: File,
    captchaResult: any
  ): Promise<{
    url: string;
    signature: string;
  }> {
    const formData = new FormData();
    formData.append("file", file);
    Object.keys(captchaResult).forEach((k) => {
      formData.append(k, captchaResult[k]);
    });

    try {
      const response = await api.post(API_PATHS.MEME_CREATE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  async memeVote(
    votes: number[],
    captchaResult: any
  ): Promise<{
    signature: string;
  }> {
    try {
      const response = await api.post(API_PATHS.MEME_VOTE, {
        ...captchaResult,
        votes,
      });

      return response.data;
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  async memeVotesAll(nonce: number): Promise<{
    votes: number;
    allVotes: number;
  }> {
    const response = await api.get(API_PATHS.MEME_VOTES_ALL, { nonce });

    return response.data;
  }

  async memesVotesAll(nonces: number[]): Promise<{
    [nonce: string]: {
      votes: number;
      allVotes: number;
    };
  }> {
    if (!nonces.length) {
      return {};
    }

    const newNonces = nonces.join(",");

    const response = await api.get(API_PATHS.MEMES_VOTES_ALL + `?nonces=${newNonces}`);

    return response.data.reduce((acc, { nonce, ...votes }) => {
      acc[nonce] = votes;

      return acc;
    }, {});
  }

  async memeVotes(nonce: number, period: number): Promise<number> {
    const response = await api.get(API_PATHS.MEME_VOTES_PERIOD, { nonce, period });

    return response.data;
  }

  async memesVotes(nonces: number[], period: number): Promise<{ [nonce: string]: number }> {
    if (!nonces.length) {
      return {};
    }

    const newNonces = nonces.join(",");

    const response = await api.get(API_PATHS.MEMES_VOTES_PERIOD + `?nonces=${newNonces}`, { period });

    return response.data.reduce((acc, { nonce, votes }) => {
      acc[nonce] = votes;

      return acc;
    }, {});
  }

  async memeRarity(nonce: number): Promise<number> {
    const response = await api.get(API_PATHS.MEME_RARITY, { nonce });

    return response.data;
  }

  async memesRarity(nonces: number[]): Promise<{ [nonce: number]: number }> {
    if (!nonces.length) {
      return {};
    }

    const newNonces = nonces.join(",");

    const response = await api.get(API_PATHS.MEMES_RARITY + `?nonces=${newNonces}`);

    return response.data.reduce((acc, { nonce, rarity }) => {
      acc[nonce] = rarity;

      return acc;
    }, {});
  }

  async memeTopMeme(nonce: number): Promise<number> {
    const response = await api.get(API_PATHS.MEME_TOP_MEME, { nonce });

    return response.data;
  }

  async memesTopMeme(nonces: number[]): Promise<{ [nonce: number]: number }> {
    if (!nonces.length) {
      return {};
    }

    const newNonces = nonces.join(",");

    const response = await api.get(API_PATHS.MEMES_TOP_MEME + `?nonces=${newNonces}`);

    return response.data.reduce((acc, { nonce, topNonce }) => {
      if (topNonce) {
        acc[nonce] = topNonce;
      }

      return acc;
    }, {});
  }

  async memesRestricted(): Promise<string[]> {
    try {
      const response = await api.get(API_PATHS.MEMES_RESTRICTED);

      return response.data;
    } catch (e) {
      return [];
    }
  }
}

const memeClient = new MemeClient();

export default memeClient;
