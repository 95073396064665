
import VotesSubmit from '@/components/info/VotesSubmit.vue';
import { USER_ACTIONS, USER_GETTERS } from '@/store/user';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import MessageModal from '@/components/info/MessageModal.vue';
import { MEME_ACTIONS, MEME_GETTERS } from '@/store/meme';
import { VOTE_ACTIONS } from '@/store/vote';
import ConfirmModal from '@/components/info/ConfirmModal.vue';
import { BASE_MUTATIONS } from '@/store';
import PageLoader from '@/components/helpers/PageLoader.vue';
import TransactionsToast from '@/components/info/TransactionsToast.vue';
import { ROUTES } from '@/router/constants';
import { AUCTION_ACTIONS } from '@/store/auction';
import SidebarMobile from '@/components/base/Sidebar.vue';
import GENERAL from '@/constants/general';
import { REFERRAL_ACTIONS, REFERRAL_GETTERS } from '@/store/referrals';

export default {
  name: 'App',
  components: {
    SidebarMobile,
    TransactionsToast,
    PageLoader,
    ConfirmModal,
    VotesSubmit,
    MessageModal,
  },
  created() {
    this.ROUTES = ROUTES;
    this.GENERAL = GENERAL;
  },
  setup() {
    const store = useStore();

    const account = computed(() => store.getters[USER_GETTERS.ACCOUNT_ELROND]);
    const address = computed(() => store.getters[USER_GETTERS.ADDRESS_ELROND]);
    const currentPeriod = computed(() => store.getters[MEME_GETTERS.CURRENT_PERIOD]);
    const contractReferer = computed(() => store.getters[REFERRAL_GETTERS.CONTRACT_REFERER]);

    const loading = ref(true);

    watch([address, currentPeriod, contractReferer], () => {
      if (!address.value || !currentPeriod.value) {
        return;
      }

      store.dispatch(VOTE_ACTIONS.GET_USER_REMAINING, address.value);
    });

    onMounted(async () => {
      loading.value = true;

      try {
        await store.dispatch(REFERRAL_ACTIONS.HANDLE_REFERER);

        await store.dispatch(USER_ACTIONS.LOGIN_RECHECK);
      } catch (e) {
        console.error(e);

        store.commit(BASE_MUTATIONS.MESSAGE_MODAL, {
          variant: 'danger',
          message: 'messages.error.general',
        });
      }

      try {
        await store.dispatch(MEME_ACTIONS.GET_CURRENT_PERIOD);
        await store.dispatch(MEME_ACTIONS.GET_RESTRICTED);

        loading.value = false;
      } catch (e) {
        console.error(e);

        loading.value = null;

        store.commit(BASE_MUTATIONS.MESSAGE_MODAL, {
          variant: 'danger',
          message: 'messages.error.general',
        });
      }

      try {
        await store.dispatch(AUCTION_ACTIONS.GET_CUSTOM_PERIODS);
      } catch (e) {
        // Do nothing, since this does not matter that much
      }
    });

    // Get the account referer
    watch(account, (value, oldValue) => {
      if (value && value !== oldValue) {
        store.dispatch(REFERRAL_ACTIONS.GET_REFERER, value);
      }
    });

    return {
      address,
      loading,
    };
  },
};
