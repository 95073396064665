import elrondHelper from '@/helpers/elrond';
import storageHelper from '@/helpers/storage';
import elrondApiHelper, { NFT_POST } from '@/helpers/elrondApi';
import storeHelper from '@/helpers/store';
import { NFT_POST_VIEW } from '@/store/meme';
import { Account, Address, ESDTTransferPayloadBuilder, TokenPayment, Transaction } from '@multiversx/sdk-core';
import generalClient from '@/api/general';
import memeClient from '@/api/meme';
import authClient from '@/api/auth';
import { WalletConnectV2Provider } from '@multiversx/sdk-wallet-connect-provider';
import { ExtensionProvider } from '@multiversx/sdk-extension-provider';
import { HWProvider } from '@multiversx/sdk-hw-provider';
import BLOCKCHAIN from '@/constants/blockchain';
import ELROND from '@/constants/elrond';
import { REFERRAL_MUTATIONS } from '@/store/referrals';
import { ESDT_TRANSFER_GAS_LIMIT } from '@multiversx/sdk-core/out/constants';

export const USER_GETTERS = {
  ADDRESS_ELROND: "userAddressElrond",
  ACCOUNT_ELROND: "userAccountElrond",
  MEMES: "userMemes",
  TOKENS: "userTokens",
};

export const USER_MUTATIONS = {
  ADDRESS_ELROND: 'userAddressElrond',
  ACCOUNT_ELROND: 'userAccountElrond',
  MEMES: 'userMemes',
  MEMES_MODIFY: 'userMemesModify',
  SET_ADDRESS_LAST_MEME_TIME: 'userSetAddressLastMemeTime',
  TOKENS: "userTokens",
  TOKEN_UPDATE: "userTokenUpdate",
};

export const USER_ACTIONS = {
  LOGIN_RECHECK: 'userLoginRecheck',
  LOGIN_MAIAR: 'userLoginMaiar',
  POST_LOGIN_MAIAR: 'userPostLoginMaiar',
  LOGIN_EXTENSION: 'userLoginExtension',
  LOGIN_LEDGER: 'userLoginLedger',
  POST_LOGIN_LEDGER: 'userPostLoginLedger',
  LOGOUT_ELROND: 'userLogoutElrond',

  BACKEND_GET_LOGIN_TOKEN: 'userBackendGetLoginToken',

  GET_ADDRESS_LAST_MEME_TIME: 'userGetAddressLastMemeTime',

  GET_CREATED_MEMES: 'userGetCreatedMemes',
  GET_WALLET_MEMES: 'userGetWalletMemes',
  GET_WALLET_TOP_MEMES: 'userGetWalletTopMemes',
  RELOAD_ACCOUNT: 'userReloadAccount',
  GET_TOKEN_BALANCE: 'userGetTokenBalance',
  TIP: 'userTip',

  LOGIN_BACKEND: 'userLoginBackend',
};

interface IUserStore {
  state: () => {
    addressElrond: string | boolean;
    accountElrond: Account;
    memes: any;
    tokens: { [key: string]: TokenPayment };
  };

  [index: string]: any;
}

export const userStore: IUserStore = {
  state: () => ({
    addressElrond: false,
    accountElrond: null,
    memes: null,
    tokens: {},
  }),
  getters: {
    [USER_GETTERS.ADDRESS_ELROND](state) {
      return state.addressElrond;
    },
    [USER_GETTERS.ACCOUNT_ELROND](state) {
      return state.accountElrond;
    },
    [USER_GETTERS.MEMES](state) {
      return state.memes;
    },
    [USER_GETTERS.TOKENS](state) {
      return state.tokens;
    },
  },
  mutations: {
    [USER_MUTATIONS.ADDRESS_ELROND](state, address) {
      state.addressElrond = address;
    },
    [USER_MUTATIONS.ACCOUNT_ELROND](state, account) {
      state.accountElrond = account;
    },
    [USER_MUTATIONS.MEMES](state, memes) {
      state.memes = memes;
    },
    [USER_MUTATIONS.MEMES_MODIFY](state, newMeme) {
      state.memes = storeHelper.editList(state.memes, newMeme);
    },
    [USER_MUTATIONS.SET_ADDRESS_LAST_MEME_TIME](_, time) {
      storageHelper.setAddressLastMemeTime(time);
    },
    [USER_MUTATIONS.TOKENS](state, tokens) {
      state.tokens = tokens;
    },
    [USER_MUTATIONS.TOKEN_UPDATE](state, { token, balance }) {
      state.tokens[token] = balance;
    },
  },
  actions: {
    async [USER_ACTIONS.LOGIN_RECHECK]({ dispatch }) {
      // Also require backend token to be set
      if (!storageHelper.getBackendAccessToken()) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);

        return;
      }

      // Check if there is an extension login address saved
      let initialAddress = storageHelper.getExtensionLogin();
      if (initialAddress) {
        await dispatch(USER_ACTIONS.LOGIN_EXTENSION, initialAddress);

        return;
      }

      // Check if there is a Maiar login address saved
      initialAddress = storageHelper.getMaiarLogin();
      if (initialAddress) {
        await dispatch(USER_ACTIONS.LOGIN_MAIAR, {
          handleOnLogin: () => dispatch(USER_ACTIONS.POST_LOGIN_MAIAR, true),
          handleOnLogout: () => dispatch(USER_ACTIONS.LOGOUT_ELROND),
          isRecheck: true,
        });

        return;
      }

      initialAddress = storageHelper.getLedgerLogin();
      if (initialAddress !== null) {
        try {
          await dispatch(USER_ACTIONS.LOGIN_LEDGER, initialAddress);

          return;
        } catch (e) {
          console.error(e);
        }
      }

      dispatch(USER_ACTIONS.LOGOUT_ELROND);
    },

    async [USER_ACTIONS.LOGIN_MAIAR]({ dispatch }, { handleOnLogin, handleOnLogout, isRecheck = false }) {
      const initialized = await elrondHelper.initializeMaiar(handleOnLogin, handleOnLogout);

      if (!initialized) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);
        alert('Something went wrong...');

        return null;
      }

      if (isRecheck) {
        return true;
      }

      const token = storageHelper.getBackendLoginToken();
      const url = await elrondHelper.login('', token);

      return url;
    },

    async [USER_ACTIONS.POST_LOGIN_MAIAR]({ commit, dispatch }, isRecheck = false) {
      const address = await elrondHelper.getAddress();

      if (!address) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);
        alert('Something went wrong...');

        return null;
      }

      if (!isRecheck) {
        const signature = await (elrondHelper.provider as WalletConnectV2Provider).getSignature();

        await dispatch(USER_ACTIONS.LOGIN_BACKEND, { address, signature });
      }

      commit(USER_MUTATIONS.ADDRESS_ELROND, address);
      storageHelper.setMaiarLogin(address);
      await dispatch(USER_ACTIONS.RELOAD_ACCOUNT, address);

      return address;
    },

    async [USER_ACTIONS.LOGIN_EXTENSION]({ commit, dispatch }, initialAddress) {
      const initialized = await elrondHelper.initializeExtension(initialAddress);

      if (!initialized) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);
        alert('Extension not installed...');

        return null;
      }

      let address = initialAddress;
      if (!initialAddress) {
        try {
          address = await elrondHelper.login('', storageHelper.getBackendLoginToken());
        } catch (e) {
          // Error handled further down
        }
      }

      if (!address) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);

        return null;
      }

      if (!initialAddress) {
        const signature = (elrondHelper.provider as ExtensionProvider).account.signature;

        await dispatch(USER_ACTIONS.LOGIN_BACKEND, { address, signature });
      }

      commit(USER_MUTATIONS.ADDRESS_ELROND, address);
      storageHelper.setExtensionLogin(address);
      await dispatch(USER_ACTIONS.RELOAD_ACCOUNT, address);

      return address;
    },

    async [USER_ACTIONS.LOGIN_LEDGER]({ dispatch }, initialAddress = null) {
      const initialized = await elrondHelper.initializeLedger();

      if (!initialized) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);

        return false;
      }

      if (initialAddress) {
        return await dispatch(USER_ACTIONS.POST_LOGIN_LEDGER, initialAddress);
      }

      return await (elrondHelper.provider as HWProvider).getAccounts();
    },
    async [USER_ACTIONS.POST_LOGIN_LEDGER](
      { dispatch, commit },
      { addressIndex, securityToken = null, address = null }
    ) {
      if (!address) {
        let signature: Buffer = null;

        // @ts-ignore
        ({ address, signature } = await elrondHelper.login('', securityToken, addressIndex));

        if (address) {
          await dispatch(USER_ACTIONS.LOGIN_BACKEND, { address, signature: signature.toString('hex') });
        }
      } else {
        await elrondHelper.login('', '', addressIndex);
      }

      if (!address) {
        dispatch(USER_ACTIONS.LOGOUT_ELROND);
        alert('Something went wrong...');

        return null;
      }

      commit(USER_MUTATIONS.ADDRESS_ELROND, address);
      storageHelper.setLedgerLogin(addressIndex, address);
      await dispatch(USER_ACTIONS.RELOAD_ACCOUNT, address);

      return address;
    },

    [USER_ACTIONS.LOGOUT_ELROND]({ commit, getters }) {
      elrondHelper.logout();

      storageHelper.clearLogins(true, getters[USER_GETTERS.ADDRESS_ELROND]);

      commit(USER_MUTATIONS.ADDRESS_ELROND, null);
      commit(USER_MUTATIONS.ACCOUNT_ELROND, null);
      commit(USER_MUTATIONS.TOKENS, {});

      commit(REFERRAL_MUTATIONS.RESET);

      // Need actual string here, because of circular references
      commit('resetTransactionToast');
    },

    async [USER_ACTIONS.BACKEND_GET_LOGIN_TOKEN]() {
      const token = await authClient.authToken();

      storageHelper.setBackendLoginToken(token);
    },

    async [USER_ACTIONS.GET_ADDRESS_LAST_MEME_TIME]({ commit }, addressElrond) {
      let time = storageHelper.getAddressLastMemeTime();

      if (!time) {
        time = await generalClient.addressLastMemeTime(addressElrond);

        commit(USER_MUTATIONS.SET_ADDRESS_LAST_MEME_TIME, time);
      }

      return time;
    },

    // TODO: This should be changed after Microservice has database since it no longer works this way
    async [USER_ACTIONS.GET_CREATED_MEMES]({ commit, getters }, { address, page }) {
      const total = await elrondApiHelper.getCreatedNftsCount(address);
      const response = await elrondApiHelper.getCreatedNfts(address, page, total);

      await commitListWithAllInfo(response, page, commit, total, getters[USER_GETTERS.MEMES]?.items);
    },
    async [USER_ACTIONS.GET_WALLET_MEMES]({ commit, getters }, { address, page }) {
      const total = await elrondApiHelper.getAddressNftsCount(address);
      const response = await elrondApiHelper.getAddressNfts(address, page, total);

      await commitListWithAllInfo(response, page, commit, total, getters[USER_GETTERS.MEMES]?.items);
    },
    async [USER_ACTIONS.GET_WALLET_TOP_MEMES]({ commit, getters }, { address, page }) {
      const total = await elrondApiHelper.getAddressNftsCount(address, BLOCKCHAIN.TOP_NFT_TOKEN_IDENTIFIER);
      const response = await elrondApiHelper.getAddressNfts(address, page, total, BLOCKCHAIN.TOP_NFT_TOKEN_IDENTIFIER);

      await commitListWithAllInfo(response, page, commit, total, getters[USER_GETTERS.MEMES]?.items);
    },
    async [USER_ACTIONS.RELOAD_ACCOUNT]({ commit, getters }) {
      const account = await elrondHelper.getAccount(getters[USER_GETTERS.ADDRESS_ELROND]);

      commit(USER_MUTATIONS.ACCOUNT_ELROND, account);
    },
    async [USER_ACTIONS.GET_TOKEN_BALANCE]({ commit, dispatch, getters }, { token, decimals, force = false }) {
      if (!getters[USER_GETTERS.ACCOUNT_ELROND]) {
        return;
      }

      if (token in getters[USER_GETTERS.TOKENS] && !force) {
        return;
      }

      if (ELROND.EGLD_TOKEN === token) {
        if (force) {
          await dispatch(USER_ACTIONS.RELOAD_ACCOUNT);
        }

        const balance = TokenPayment.egldFromBigInteger(getters[USER_GETTERS.ACCOUNT_ELROND].balance);

        commit(USER_MUTATIONS.TOKEN_UPDATE, { token, balance } );

        return balance;
      }

      try {
        const tokenBalance = await elrondApiHelper.getAccountTokenBalance(getters[USER_GETTERS.ADDRESS_ELROND], token);

        const balance = TokenPayment.fungibleFromBigInteger(tokenBalance.identifier, tokenBalance.balance, decimals);

        commit(USER_MUTATIONS.TOKEN_UPDATE, { token, balance } );

        return balance;
      } catch (e) {
        commit(USER_MUTATIONS.TOKEN_UPDATE, { token, balance: null } );

        return null;
      }
    },
    async [USER_ACTIONS.TIP](_, { accountElrond, payment, receiver }: {
      accountElrond: Account,
      payment: TokenPayment,
      receiver: string,
    }) {
      const payload = new ESDTTransferPayloadBuilder()
        .setPayment(payment)
        .build();

      const transaction = new Transaction({
        sender: accountElrond.address,
        receiver: new Address(receiver),
        value: TokenPayment.egldFromAmount(0),
        gasLimit: ESDT_TRANSFER_GAS_LIMIT,
        data: payload,
        nonce: accountElrond.nonce,
        chainID: elrondHelper.networkConfig.ChainID,
      });

      return await elrondHelper.sendTransaction(transaction, accountElrond);
    },

    async [USER_ACTIONS.LOGIN_BACKEND]({ dispatch }, { address, signature }) {
      const token = storageHelper.getBackendLoginToken();

      if (!token) {
        await dispatch(USER_ACTIONS.LOGOUT_ELROND);

        alert('Backend login failed...');
        throw new Error('Backend login token missing');
      }

      try {
        const accessToken = await authClient.authLogin(address, token, signature);

        storageHelper.setBackendAccessToken(accessToken);
      } catch (e) {
        await dispatch(USER_ACTIONS.LOGOUT_ELROND);

        alert('Backend login failed...');
        throw new Error('Backend login failed');
      }
    },
  },
};

async function commitListWithAllInfo(response: NFT_POST[], page, commit, total: number, oldMemes) {
  const allNonces = response.map(nft => nft.nonce);
  const nftsVotes = await memeClient.memesVotesAll(allNonces);
  const nftsRarity = await memeClient.memesRarity(allNonces);

  const memes = response.map((meme): NFT_POST_VIEW => {
    const votes = nftsVotes[meme.nonce];
    const actualRarity = nftsRarity[meme.nonce];

    return {
      ...meme,
      ...votes,
      actualRarity,
    };
  });

  if (0 === page) {
    storeHelper.commitList(commit, USER_MUTATIONS.MEMES, memes, page, total);

    return;
  }

  const newMemes = [...oldMemes, ...memes];

  storeHelper.commitList(commit, USER_MUTATIONS.MEMES, newMemes, page, total);
}
