import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "text-muted text-center ms-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text-muted text-center ms-2" }
const _hoisted_5 = {
  key: 0,
  class: "mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_select, {
      modelValue: $setup.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
      options: Object.keys(this.customTokens || this.TIP_TOKENS),
      clearable: false,
      searchable: false,
      class: "token-dropdown-container input-group-text p-0",
      disabled: $props.disabled,
      transition: ""
    }, {
      option: _withCtx(({ label }) => [
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: $setup.getImgUrl(label),
            width: "32",
            height: "32"
          }, null, 8, _hoisted_1),
          _createElementVNode("strong", _hoisted_2, _toDisplayString(this.TIP_TOKENS[label].name), 1)
        ])
      ]),
      "selected-option": _withCtx(({ label }) => [
        _createElementVNode("img", {
          src: $setup.getImgUrl(label),
          width: "32",
          height: "32"
        }, null, 8, _hoisted_3),
        _createElementVNode("strong", _hoisted_4, _toDisplayString(this.TIP_TOKENS[label].name), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "options", "disabled"]),
    ($setup.accountElrond && !$props.notBalance)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(this.formatterHelper.formatTokenPayment($setup.tokenBalance, 8, this.TIP_TOKENS[$setup.value].name)), 1))
      : _createCommentVNode("", true)
  ], 64))
}