import { Account } from '@multiversx/sdk-core';
import storageHelper from '@/helpers/storage';
import GENERAL from '@/constants/general';
import memeStakingContract, { MAX_REFERALS_INFO } from '@/contracts/memeStaking';

export const REFERRAL_GETTERS = {
  REFERER: "referralReferer",
  CONTRACT_REFERER: "referralContractReferer",
  MAX_REFERALS_INFO: 'referralMaxReferalsInfo',
};

export const REFERRAL_MUTATIONS = {
  REFERER: 'referralReferer',
  CONTRACT_REFERER: "referralContractReferer",
  MAX_REFERALS_INFO: 'referralMaxReferalsInfo',
  RESET: 'referralReset',
}

export const REFERRAL_ACTIONS = {
  GET_REFERER: 'referralGetReferer',
  HANDLE_REFERER: 'referralHandleReferer',
  GET_MAX_REFERALS_INFO: 'referralGetMaxNumberOfReferals',
  USE_REFERER: 'referralUseReferer',
}

interface IReferralStore {
  state: () => {
    referer: string | null;
    contractReferer: string | null;
    maxReferalsInfo: MAX_REFERALS_INFO | null;
  };

  [index: string]: any;
}

export const referralsStore: IReferralStore = {
  state: () => ({
    referer: null,
    contractReferer: null,
    maxReferalsInfo: null,
  }),
  getters: {
    [REFERRAL_GETTERS.REFERER](state) {
      return state.referer;
    },
    [REFERRAL_GETTERS.CONTRACT_REFERER](state) {
      return state.contractReferer;
    },
    [REFERRAL_GETTERS.MAX_REFERALS_INFO](state) {
      return state.maxReferalsInfo;
    },
  },
  mutations: {
    [REFERRAL_MUTATIONS.REFERER](state, referer) {
      state.referer = referer;
    },
    [REFERRAL_MUTATIONS.CONTRACT_REFERER](state, contractReferer) {
      state.contractReferer = contractReferer;
    },
    [REFERRAL_MUTATIONS.MAX_REFERALS_INFO](state, maxReferalsInfo) {
      state.maxReferalsInfo = maxReferalsInfo;
    },
    [REFERRAL_MUTATIONS.RESET](state) {
      state.referer = null;
      state.maxReferalsInfo = null;
    }
  },
  actions: {
    async [REFERRAL_ACTIONS.GET_REFERER]({ commit }, account: Account) {
      const address = account.address.bech32();

      let referer = storageHelper.getReferer(address);

      if (referer && referer !== address) {
        const newReferer = storageHelper.getReferer('');

        if (!newReferer) {
          commit(REFERRAL_MUTATIONS.REFERER, referer);
          commit(REFERRAL_MUTATIONS.CONTRACT_REFERER, referer);

          return referer;
        }
      }

      referer = await memeStakingContract.getReferer(account);

      commit(REFERRAL_MUTATIONS.CONTRACT_REFERER, referer);

      if (!referer) {
        referer = storageHelper.getReferer('');

        if (referer === address) {
          storageHelper.setReferer('', null);
          referer = null;
        }
      } else {
        // Only set this if it is actually saved in the contract, used for local cache
        storageHelper.setReferer(address, referer);
      }

      commit(REFERRAL_MUTATIONS.REFERER, referer);

      return referer;
    },
    async [REFERRAL_ACTIONS.HANDLE_REFERER]() {
      const query = new URLSearchParams(window.location.search);

      if (!query.has(GENERAL.REFERER_PARAM)) {
        return;
      }

      const referer = query.get(GENERAL.REFERER_PARAM);

      storageHelper.setReferer('', referer);

      query.delete(GENERAL.REFERER_PARAM);

      window.location.search = query.toString();
    },
    async [REFERRAL_ACTIONS.GET_MAX_REFERALS_INFO]({ commit, getters }, address: string) {
      if (null !== getters[REFERRAL_GETTERS.MAX_REFERALS_INFO]) {
        return getters[REFERRAL_GETTERS.MAX_REFERALS_INFO];
      }

      const maxReferalsInfo = await memeStakingContract.getMaxReferalsInfo(address);

      commit(REFERRAL_MUTATIONS.MAX_REFERALS_INFO, maxReferalsInfo);

      return maxReferalsInfo;
    },
    async [REFERRAL_ACTIONS.USE_REFERER](_, { accountElrond, referer }) {
      return await memeStakingContract.useReferer(accountElrond, referer);
    },
  },
};
